<template>
  <div class="row">
<!--    <div class="col-lg-12 d-flex justify-content-end mb-h">-->
<!--      <div class="pl-1">-->
<!--        <button class="btn btn-info btn-md mb-1 mr-1 " @click="refreshExports">-->
<!--          {{ $t('radar.refreshButtonCaption') }}-->
<!--        </button>-->
<!--        <button class="btn btn-warning btn-md mb-1 " @click="openExportModal(true)"-->
<!--                v-if="!isDisable">-->
<!--          {{ $t('radar.addNew') }}-->
<!--        </button>-->

<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-lg-12">-->
<!--      <div class="row">-->
<!--        <div class="form-group col-lg-9 float-left">-->
<!--          <div class="">-->
<!--            <div class="d-flex flex-wrap">-->
<!--              <div class="input-group input-group-sm filter-in-row mr-h search-or-sorting-item">-->
<!--                &lt;!&ndash;            <span class="input-group-addon fa fa-circle-o-notch" v-if="searchSpinner"></span>&ndash;&gt;-->
<!--                &lt;!&ndash;            <span v-else class="input-group-addon fa-spin fa fa-search" />&ndash;&gt;-->
<!--                <span class="input-group-addon">-->
<!--                  <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>-->
<!--                  <i class="fa fa-search" v-else></i>-->
<!--                </span>-->
<!--                <input-->
<!--                  id="search"-->
<!--                  name="search"-->
<!--                  :placeholder="$t('general.search')"-->
<!--                  class="form-control"-->
<!--                  v-model="filters.query"-->
<!--                  @input="searchInput"-->
<!--                  autocomplete="false"-->
<!--                />-->
<!--                <span-->
<!--                  class="clear-input clear-input-fix text-muted fa fa-remove"-->
<!--                  v-if="filters.query !== ''"-->
<!--                  @click="clearSerchInput"-->
<!--                />-->
<!--              </div>-->
<!--              <div class="input-group input-group-sm filter-in-row mr-h search-or-sorting-item">-->
<!--                <span class="input-group-addon">-->
<!--                  <i-->
<!--                    class="fa fa-sort-amount-desc text-primary icon-sort"-->
<!--                    v-if="filters.sortOrder === 'desc'"-->
<!--                    @click="toggleSortOrder"-->
<!--                  ></i>-->
<!--                  <i-->
<!--                    class="fa fa-sort-amount-asc text-primary icon-sort"-->
<!--                    v-if="filters.sortOrder === 'asc'"-->
<!--                    @click="toggleSortOrder"-->
<!--                  ></i>-->
<!--                </span>-->
<!--                <select-->
<!--                  @change="this.getExportsWithFilterQuery"-->
<!--                  class="form-control form-control-sm"-->
<!--                  v-model="filters.sortByFilter"-->
<!--                >-->
<!--                  <option :selected="true" value="Name">{{ $t('radar.sortTypeName') }}</option>-->
<!--                  <option :selected="true" value="Created">{{ $t('radar.sortTypeCreated') }}</option>-->
<!--                  <option :selected="true" value="Updated">{{ $t('radar.sortTypeUpdated') }}</option>-->
<!--                </select>-->
<!--              </div>-->

<!--              <div class="input-group input-group-sm filter-in-row mr-h search-or-sorting-item">-->
<!--                <span class="input-group-addon fa fa-building-o" />-->
<!--                <select @change="changeCompany" class="form-control form-control-sm" v-model="filters.company">-->
<!--                  <option :value="'All companys'">{{ $t('general.allCompanys') }}</option>-->
<!--                  <option v-for="company in companys" :value="company" :key="company.name">-->
<!--                    {{ company.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->

<!--              <div-->
<!--                class="input-group input-group-sm filter-in-row mr-h search-or-sorting-item min-width-300"-->
<!--                v-if="this.filters.company === 'All companys'"-->
<!--              >-->
<!--&lt;!&ndash;                <span class="input-group-addon fa fa-map-marker" />&ndash;&gt;-->
<!--&lt;!&ndash;                <select&ndash;&gt;-->
<!--&lt;!&ndash;                  @change="getExportsWithFilterQuery"&ndash;&gt;-->
<!--&lt;!&ndash;                  class="form-control form-control-sm"&ndash;&gt;-->
<!--&lt;!&ndash;                  v-model="filters.location"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                  <option :value="'All locations'">{{ $t('general.allLocations') }}</option>&ndash;&gt;-->
<!--&lt;!&ndash;                  <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{ location.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </option>&ndash;&gt;-->
<!--&lt;!&ndash;                </select>&ndash;&gt;-->

<!--                                                                   <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-monitoringPeriodicTasks&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="locationInFiltersData"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFilters"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                           :disabled="filters.location === 'All locations'"-->
<!--                          @input="getExportsWithFilterQuery"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          v-model="filters.with_childs"-->
<!--                          class=""-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->

<!--              </div>-->
<!--              <div class="input-group input-group-sm filter-in-row mr-h search-or-sorting-item min-width-300" v-else>-->
<!--                <span class="input-group-addon fa fa-map-marker" />-->
<!--                <select-->
<!--                  @change="getExportsWithFilterQuery"-->
<!--                  class="form-control form-control-sm"-->
<!--                  v-model="filters.location"-->
<!--                >-->
<!--                  <option-->
<!--                    v-for="location in locationsObjbyIds(this.filters.company.locations)"-->
<!--                    :value="location.id"-->
<!--                    :key="location.id"-->
<!--                  >-->
<!--                    {{ location.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--&lt;!&ndash;              <div&ndash;&gt;-->
<!--&lt;!&ndash;                  class=" d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper mr-h">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div&ndash;&gt;-->
<!--&lt;!&ndash;                    class="  d-flex align-items-end justify-content-start"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                      :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
<!--&lt;!&ndash;                      v-model="filters.with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                      class="switcher-with-childs-for-use-in-filters"&ndash;&gt;-->
<!--&lt;!&ndash;                      @change="getExportsWithFilterQuery"&ndash;&gt;-->
<!--&lt;!&ndash;                    />&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div&ndash;&gt;-->
<!--&lt;!&ndash;                    class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">&ndash;&gt;-->
<!--&lt;!&ndash;                    <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--              &lt;!&ndash;              <div class="input-group input-group-sm filter-in-row mr-h search-or-sorting-item">&ndash;&gt;-->
<!--              &lt;!&ndash;                <span class="input-group-addon fa fa-map-marker"/>&ndash;&gt;-->
<!--              &lt;!&ndash;                <select @change="getExportsWithFilterQuery" class="form-control form-control-sm"&ndash;&gt;-->
<!--              &lt;!&ndash;                        v-model="filters.location"&ndash;&gt;-->
<!--              &lt;!&ndash;                :title="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'? $t('aps.filterByCompanyDisableSortingByLocationMsg') : ''"&ndash;&gt;-->
<!--              &lt;!&ndash;                :disabled="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'">&ndash;&gt;-->
<!--              &lt;!&ndash;                  <option :value="'All locations'">{{ $t('general.allLocations') }}</option>&ndash;&gt;-->
<!--              &lt;!&ndash;                  <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--              &lt;!&ndash;                    {{ location.name }}&ndash;&gt;-->
<!--              &lt;!&ndash;                  </option>&ndash;&gt;-->
<!--              &lt;!&ndash;                </select>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->
<!--              &lt;!&ndash;              <div class="input-group input-group-sm filter-in-row mr-h search-or-sorting-item">&ndash;&gt;-->
<!--              &lt;!&ndash;                <span class="input-group-addon fa fa-building-o"/>&ndash;&gt;-->
<!--              &lt;!&ndash;                <select @change="getExportsWithFilterQuery" class="form-control form-control-sm"&ndash;&gt;-->
<!--              &lt;!&ndash;                        v-model="filters.company">&ndash;&gt;-->
<!--              &lt;!&ndash;                  <option :value="'All companys'">{{ $t('general.allCompanys') }}</option>&ndash;&gt;-->
<!--              &lt;!&ndash;                  <option v-for="company in companyList" :value="company" :key="company.name">&ndash;&gt;-->
<!--              &lt;!&ndash;                    {{ company.name }}&ndash;&gt;-->
<!--              &lt;!&ndash;                  </option>&ndash;&gt;-->
<!--              &lt;!&ndash;                </select>&ndash;&gt;-->
<!--              &lt;!&ndash;              </div>&ndash;&gt;-->

<!--              <div class="input-group input-group-sm filter-in-row mr-h search-or-sorting-item">-->
<!--                <span class="input-group-addon fa fa-check" />-->
<!--                <select-->
<!--                  @change="getExportsWithFilterQuery"-->
<!--                  class="form-control form-control-sm"-->
<!--                  v-model="filters.status"-->
<!--                >-->
<!--                  <option :value="''">{{ $t('radar.allStatuses') }}</option>-->
<!--                  <option v-for="status in exportStatus" :value="status" :key="status">-->
<!--                    {{ $te(`radar.${status}`) ? $t(`radar.${status}`) : status }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-if="!isBeelineBranding" class="btn-group btn-group-sm w-100 mt-h" data-toggle="button">-->
<!--              <label class="btn btn btn-outline-info" :class="{ active: filters.typeFilter === 'yandex' }">-->
<!--                {{ $t('radar.yandex') }}-->
<!--                <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'yandex')" />-->
<!--              </label>-->
<!--              <label class="btn btn btn-outline-info" :class="{ active: filters.typeFilter === 'mytarget' }">-->
<!--                myTarget-->
<!--                <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'mytarget')" />-->
<!--              </label>-->
<!--              <label class="btn btn btn-outline-info" :class="{ active: filters.typeFilter === 'email' }">-->
<!--                Email-->
<!--                <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'email')" />-->
<!--              </label>-->
<!--              <label-->
<!--                v-if="isNmsTest"-->
<!--                class="btn btn btn-outline-info"-->
<!--                :class="{ active: filters.typeFilter === 'beepro' }"-->
<!--              >-->
<!--                {{ $t('radar.beepro') }}-->
<!--                <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'beepro')" />-->
<!--              </label>-->
<!--            </div>-->

<!--            &lt;!&ndash;            <div  v-if="isBeelineBranding" class="btn-group btn-group-sm w-100 mt-0 mb-0" data-toggle="button">&ndash;&gt;-->
<!--            &lt;!&ndash;&lt;!&ndash;              <label class="btn btn btn-outline-warning"&ndash;&gt;&ndash;&gt;-->
<!--            &lt;!&ndash;&lt;!&ndash;                     :class="{ active: filters.typeFilter === 'email' }">&ndash;&gt;&ndash;&gt;-->
<!--            &lt;!&ndash;&lt;!&ndash;                Email&ndash;&gt;&ndash;&gt;-->
<!--            &lt;!&ndash;&lt;!&ndash;                <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'email')"/>&ndash;&gt;&ndash;&gt;-->
<!--            &lt;!&ndash;&lt;!&ndash;              </label>&ndash;&gt;&ndash;&gt;-->
<!--            &lt;!&ndash;&lt;!&ndash;              <label class="btn btn btn-outline-warning"&ndash;&gt;&ndash;&gt;-->
<!--            &lt;!&ndash;&lt;!&ndash;                     :class="{ active: filters.typeFilter === 'beepro' }">&ndash;&gt;&ndash;&gt;-->
<!--            &lt;!&ndash;&lt;!&ndash;                {{$t('radar.beepro')}}&ndash;&gt;&ndash;&gt;-->
<!--            &lt;!&ndash;&lt;!&ndash;                <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'beepro')"/>&ndash;&gt;&ndash;&gt;-->
<!--            &lt;!&ndash;&lt;!&ndash;              </label>&ndash;&gt;&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->

<!--            &lt;!&ndash;        тут блок с фильтром по времени&ndash;&gt;-->
<!--            <div-->
<!--              class="btn-group btn-group-sm w-100 mt-h datepicker-block-in-filter-radar-export d-flex align-items-start"-->
<!--            >-->
<!--              <div class="d-flex flex-column align-items-left datepicker-block-in-filter-radar-export-switcher">-->
<!--                <div class="p-0 m-0 border-0">-->
<!--                  <small class="align-text-top">-->
<!--                    {{ $t('aps.sortByConnectionnOrDisconnectionTimeDateRangeFilterCaption') }}-->
<!--                  </small>-->
<!--                </div>-->
<!--                <div class="d-flex align-items-end justify-content-left">-->
<!--                  <Switch-component v-model="filters.isDateRangeFilterEnabled" @change="toggleFilterByDateRange" />-->
<!--                </div>-->
<!--              </div>-->
<!--              <transition name="fadeforcpefiltersblockinradar">-->
<!--                <div v-if="filters.isDateRangeFilterEnabled" class="ml-1">-->
<!--                  <div class="btn-group btn-group-sm w-100 mt-h" data-toggle="button">-->
<!--                    <label-->
<!--                      class="btn btn btn-outline-warning"-->
<!--                      :class="{ active: filters.dataRangeFilterType === 'Created' }"-->
<!--                    >-->
<!--                      {{ $t('radar.dataRangeFilterTypeButtonCreated') }}-->
<!--                      <input type="radio" name="dateRangeFilterType" @click="setDateRangeFilterType('Created')" />-->
<!--                    </label>-->
<!--                    <label-->
<!--                      class="btn btn btn-outline-warning"-->
<!--                      :class="{ active: filters.dataRangeFilterType === 'Updated' }"-->
<!--                    >-->
<!--                      {{ $t('radar.dataRangeFilterTypeButtonUpdated') }}-->
<!--                      <input type="radio" name="dateRangeFilterType" @click="setDateRangeFilterType('Updated')" />-->
<!--                    </label>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </transition>-->
<!--              <transition name="fadeforcpefiltersblockinradar">-->
<!--                <div-->
<!--                  v-if="filters.isDateRangeFilterEnabled"-->
<!--                  class="ml-1 datepicker-block-in-filter-radar-export-datepicker"-->
<!--                >-->
<!--                  <date-picker-->
<!--                    v-if="isDatePickerVisible && filters.isDateRangeFilterEnabled"-->
<!--                    v-model="filters.drPickerDate"-->
<!--                    :on-change="getExportsWithFilterQuery"-->
<!--                  />-->
<!--                </div>-->
<!--              </transition>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash;        <div class="col-lg-3">&ndash;&gt;-->
<!--        &lt;!&ndash;          <button class="btn btn-warning btn-md mb-1 float-right" @click="openExportModal(true)"&ndash;&gt;-->
<!--        &lt;!&ndash;                  v-if="!isDisable">&ndash;&gt;-->
<!--        &lt;!&ndash;            {{ $t('radar.addNew') }}&ndash;&gt;-->
<!--        &lt;!&ndash;          </button>&ndash;&gt;-->
<!--        &lt;!&ndash;        </div>&ndash;&gt;-->

<!--        <div class="col-lg-3 d-flex flex-column justify-content-start">-->
<!--          <div class="d-flex flex-wrap justify-content-end">-->
<!--&lt;!&ndash;            <div class="">&ndash;&gt;-->
<!--&lt;!&ndash;              <button class="btn btn-info btn-md mb-1 float-right" @click="refreshExports">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ $t('radar.refreshButtonCaption') }}&ndash;&gt;-->
<!--&lt;!&ndash;              </button>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="ml-1">&ndash;&gt;-->
<!--&lt;!&ndash;              <button class="btn btn-warning btn-md mb-1 float-right" @click="openExportModal(true)" v-if="!isDisable">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ $t('radar.addNew') }}&ndash;&gt;-->
<!--&lt;!&ndash;              </button>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->
<!--          <div v-if="isBeelineBranding || isNmsTest" class="d-flex flex-wrap justify-content-end">-->
<!--            <div class="d-flex justify-content-end w-100">-->
<!--              <a-->
<!--                :class="{ 'user-manual-link-for-beeline': isBeelineBranding, 'user-manual-link-for-nmstest': isNmsTest }"-->
<!--                target="_blank"-->
<!--                href="https://docs.google.com/document/d/1KIdXA5TBk_d-EBUUaKiXJYkwYT_w_gW9/edit?usp=sharing&ouid=110254390535068569939&rtpof=true&sd=true"-->
<!--              >-->
<!--                {{ $t('radar.usersManualLinkCaption') }}-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->



        <div class="col-lg-12 d-flex flex-column align-items-end justify-content-end mb-h">
      <div class="pl-1">

                            <div v-if="isBeelineBranding || isNmsTest" class="d-flex flex-wrap justify-content-end mb-h">
            <div class="d-flex justify-content-end w-100">
              <a
                :class="{ 'user-manual-link-for-beeline': isBeelineBranding, 'user-manual-link-for-nmstest': isNmsTest }"
                target="_blank"
                href="https://docs.google.com/document/d/1KIdXA5TBk_d-EBUUaKiXJYkwYT_w_gW9/edit?usp=sharing&ouid=110254390535068569939&rtpof=true&sd=true"
              >
                {{ $t('radar.usersManualLinkCaption') }}
              </a>
            </div>
          </div>
          <WButton info
            customClass="mb-1"
            v-if="!isDisable"
            @click="openExportModal(true)"
          >
            {{ $t('radar.addNew') }}
          </WButton>
        </div>

    </div>
    <div class="col-lg-12">
      <div class="">
        <div class="form-group col-lg-12 pl-0 ">
          <div class="row">
          <div class="col-lg-3 mb-h pr-0">
              <div class="input-group input-group-sm ">
                <!--            <span class="input-group-addon fa fa-circle-o-notch" v-if="searchSpinner"></span>-->
                <!--            <span v-else class="input-group-addon fa-spin fa fa-search" />-->
                <span class="input-group-addon">
                  <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>
                  <i class="fa fa-search" v-else></i>
                </span>
                <input
                  id="search"
                  name="search"
                  :placeholder="$t('general.search')"
                  class="form-control"
                  v-model="filters.query"
                  @input="searchInput"
                  autocomplete="false"
                />
                <span
                  class="clear-input clear-input-fix text-muted fa fa-remove"
                  v-if="filters.query !== ''"
                  @click="clearSerchInput"
                />
              </div>


          </div>



          <div class="col-lg-3 mb-h pr-0" v-if="areLocationsVisible">

                          <div class="input-group input-group-sm">
                <span class="input-group-addon fa fa-building-o" />
                <select  :disabled="isSelectedDefaultLocationForRequests" @change="changeCompany" class="form-control form-control-sm" v-model="filters.company">
                  <option :value="'All companys'">{{ $t('general.allCompanys') }}</option>
                  <option v-for="company in companys" :value="company" :key="company.name">
                    {{ company.name }}
                  </option>
                </select>
              </div>

          </div>

          <div class="col-lg-3 mb-h pr-0" v-if="areLocationsVisible">
              <div
                class="input-group-for-location-select-in-AnalyticsRadar--filters"
                v-if="this.filters.company === 'All companys'"
              >
<!--                <span class="input-group-addon fa fa-map-marker" />-->
<!--                <select-->
<!--                  @change="getExportsWithFilterQuery"-->
<!--                  class="form-control form-control-sm"-->
<!--                  v-model="filters.location"-->
<!--                >-->
<!--                  <option :value="'All locations'">{{ $t('general.allLocations') }}</option>-->
<!--                  <option v-for="location in locationsList" :value="location.id" :key="location.id">-->
<!--                    {{ location.name }}-->
<!--                  </option>-->
<!--                </select>-->

                                                                   <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-AnalyticsRadar--filters"
                    enable-max-option-width
                    small-size
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                           :disabled="filters.location === 'All locations'"
                          @input="getExportsWithFilterQuery"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>

              </div>
              <div class="input-group input-group-sm" v-else>
                <span class="input-group-addon fa fa-map-marker" />
                <select
                  @change="getExportsWithFilterQuery"
                  class="form-control form-control-sm"
                  v-model="filters.location"
                >
                  <option
                    v-for="location in locationsObjbyIds(this.filters.company.locations)"
                    :value="location.id"
                    :key="location.id"
                  >
                    {{ location.name }}
                  </option>
                </select>
              </div>


          </div>

                      <div class="col-lg-3 mb-h pr-0">

                          <div class="input-group input-group-sm ">
                <span class="input-group-addon">
                  <i
                    class="fa fa-sort-amount-desc text-primary icon-sort"
                    v-if="filters.sortOrder === 'desc'"
                    @click="toggleSortOrder"
                  ></i>
                  <i
                    class="fa fa-sort-amount-asc text-primary icon-sort"
                    v-if="filters.sortOrder === 'asc'"
                    @click="toggleSortOrder"
                  ></i>
                </span>
                <select
                  @change="this.getExportsWithFilterQuery"
                  class="form-control form-control-sm"
                  v-model="filters.sortByFilter"
                >
                  <option :selected="true" value="Name">{{ $t('radar.sortTypeName') }}</option>
                  <option :selected="true" value="Created">{{ $t('radar.sortTypeCreated') }}</option>
                  <option :selected="true" value="Updated">{{ $t('radar.sortTypeUpdated') }}</option>
                </select>
              </div>

          </div>

          <div class="col-lg-3 mb-h pr-0">

                          <div class="input-group input-group-sm ">
                <span class="input-group-addon fa fa-check" />
                <select
                  @change="getExportsWithFilterQuery"
                  class="form-control form-control-sm"
                  v-model="filters.status"
                >
                  <option :value="''">{{ $t('radar.allStatuses') }}</option>
                  <option v-for="status in exportStatus" :value="status" :key="status">
                    {{ $te(`radar.${status}`) ? $t(`radar.${status}`) : status }}
                  </option>
                </select>
              </div>

          </div>
        </div>



          <div class="">
            <div v-if="!isBeelineBranding" class="btn-group btn-group-sm w-100 mt-h" data-toggle="button">
              <label class="btn btn btn-outline-info" :class="{ active: filters.typeFilter === 'yandex' }">
                {{ $t('radar.yandex') }}
                <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'yandex')" />
              </label>
              <label class="btn btn btn-outline-info" :class="{ active: filters.typeFilter === 'mytarget' }">
                myTarget
                <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'mytarget')" />
              </label>
              <label class="btn btn btn-outline-info" :class="{ active: filters.typeFilter === 'email' }">
                Email
                <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'email')" />
              </label>
              <label
                v-if="isNmsTest"
                class="btn btn btn-outline-info"
                :class="{ active: filters.typeFilter === 'beepro' }"
              >
                {{ $t('radar.beepro') }}
                <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'beepro')" />
              </label>
            </div>

            <!--            <div  v-if="isBeelineBranding" class="btn-group btn-group-sm w-100 mt-0 mb-0" data-toggle="button">-->
            <!--&lt;!&ndash;              <label class="btn btn btn-outline-warning"&ndash;&gt;-->
            <!--&lt;!&ndash;                     :class="{ active: filters.typeFilter === 'email' }">&ndash;&gt;-->
            <!--&lt;!&ndash;                Email&ndash;&gt;-->
            <!--&lt;!&ndash;                <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'email')"/>&ndash;&gt;-->
            <!--&lt;!&ndash;              </label>&ndash;&gt;-->
            <!--&lt;!&ndash;              <label class="btn btn btn-outline-warning"&ndash;&gt;-->
            <!--&lt;!&ndash;                     :class="{ active: filters.typeFilter === 'beepro' }">&ndash;&gt;-->
            <!--&lt;!&ndash;                {{$t('radar.beepro')}}&ndash;&gt;-->
            <!--&lt;!&ndash;                <input type="radio" name="typeFilter" @click="setFilter('typeFilter', 'beepro')"/>&ndash;&gt;-->
            <!--&lt;!&ndash;              </label>&ndash;&gt;-->
            <!--            </div>-->

            <!--        тут блок с фильтром по времени-->
            <div
              class="btn-group btn-group-sm w-100 mt-h datepicker-block-in-filter-radar-export d-flex align-items-end"
            >



              <div class="d-flex flex-column align-items-left datepicker-block-in-filter-radar-export-switcher">
<!--                <div class="p-0 m-0 border-0">-->
<!--                  <small class="align-text-top">-->
<!--                    {{ $t('aps.sortByConnectionnOrDisconnectionTimeDateRangeFilterCaption') }}-->
<!--                  </small>-->
<!--                </div>-->
                <div @click="toggleSortByConnectionnOrDisconnectionTimeDateRangeFilter" role="button">
                <transition name="fadeforworktimefiltersinvisitors">
                      <i v-if="showSortByConnectionnOrDisconnectionTimeDateRangeFilter" class="icon-arrow-down"></i>
                      <i v-else class="icon-arrow-right"></i>
                </transition>
                   <span class="text-muted">{{ $t('aps.sortByConnectionnOrDisconnectionTimeDateRangeFilterCaption') }} &nbsp;</span>
                                      <transition name="fadeforworktimefiltersinvisitors">
                      <span
                        class="badge badge-light badge-success badge-wortime-wilter-enabled"
                        v-if="filters.isDateRangeFilterEnabled"
                      >
                        {{ $t('marketing.workTimeFilterEnabled') }} &nbsp;
                      </span>
                    </transition>
              </div>
                <div class="d-flex align-items-end justify-content-left" v-if="showSortByConnectionnOrDisconnectionTimeDateRangeFilter">
                  <div class="flex-column justify-content-end align-items-start">
                                      <div><label class="mb-0" >{{ $t('general.enable') }}</label></div>
                  <Switch-component v-model="filters.isDateRangeFilterEnabled" @change="toggleFilterByDateRange" />
                  </div>
              <transition name="fadeforcpefiltersblockinradar">
                <div v-if="filters.isDateRangeFilterEnabled && showSortByConnectionnOrDisconnectionTimeDateRangeFilter" class="ml-1">
                  <div class="btn-group btn-group-sm w-100 mt-h" data-toggle="button">
                    <label
                      class="btn btn btn-outline-info"
                      :class="{ active: filters.dataRangeFilterType === 'Created' }"
                    >
                      {{ $t('radar.dataRangeFilterTypeButtonCreated') }}
                      <input type="radio" name="dateRangeFilterType" @click="setDateRangeFilterType('Created')" />
                    </label>
                    <label
                      class="btn btn btn-outline-info"
                      :class="{ active: filters.dataRangeFilterType === 'Updated' }"
                    >
                      {{ $t('radar.dataRangeFilterTypeButtonUpdated') }}
                      <input type="radio" name="dateRangeFilterType" @click="setDateRangeFilterType('Updated')" />
                    </label>
                  </div>
                </div>
              </transition>
              <transition name="fadeforcpefiltersblockinradar">
                <div style="position:relative;">
                  <div
                    style="position: relative; top: -8px !important"
                    v-if="filters.isDateRangeFilterEnabled && showSortByConnectionnOrDisconnectionTimeDateRangeFilter"
                    class="ml-1 datepicker-block-in-filter-radar-export-datepicker"
                  >
                    <date-picker
                      input-class="mx-input datepicker-custom-height-for-AnalyticsRadar_27"
                      :show-icon="true"
                      v-if="isDatePickerVisible && filters.isDateRangeFilterEnabled"
                      v-model="filters.drPickerDate"
                      :on-change="getExportsWithFilterQuery"
                    />
                  </div>
                </div>

              </transition>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!--        <div class="col-lg-3">-->
        <!--          <button class="btn btn-warning btn-md mb-1 float-right" @click="openExportModal(true)"-->
        <!--                  v-if="!isDisable">-->
        <!--            {{ $t('radar.addNew') }}-->
        <!--          </button>-->
        <!--        </div>-->

        <div class="col-lg-3 d-flex flex-column justify-content-start">
          <div class="d-flex flex-wrap justify-content-end">
<!--            <div class="">-->
<!--              <button class="btn btn-info btn-md mb-1 float-right" @click="refreshExports">-->
<!--                {{ $t('radar.refreshButtonCaption') }}-->
<!--              </button>-->
<!--            </div>-->
<!--            <div class="ml-1">-->
<!--              <button class="btn btn-warning btn-md mb-1 float-right" @click="openExportModal(true)" v-if="!isDisable">-->
<!--                {{ $t('radar.addNew') }}-->
<!--              </button>-->
<!--            </div>-->
          </div>
<!--          <div v-if="isBeelineBranding || isNmsTest" class="d-flex flex-wrap justify-content-end">-->
<!--            <div class="d-flex justify-content-end w-100">-->
<!--              <a-->
<!--                :class="{ 'user-manual-link-for-beeline': isBeelineBranding, 'user-manual-link-for-nmstest': isNmsTest }"-->
<!--                target="_blank"-->
<!--                href="https://docs.google.com/document/d/1KIdXA5TBk_d-EBUUaKiXJYkwYT_w_gW9/edit?usp=sharing&ouid=110254390535068569939&rtpof=true&sd=true"-->
<!--              >-->
<!--                {{ $t('radar.usersManualLinkCaption') }}-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
          <div class=" p-0 col-lg-12 d-flex justify-content-start mb-h" >
      <div v-if="isFiltersActive" class="">
        <WButton secondary outline sm
          @click="resetAllFilters"
        >
          <i class="fa fa-close"></i>
          {{ $t('general.resetAllFilters') }}
        </WButton>
      </div>
    </div>
    </div>

    <div class="col-lg-12">
      <div v-if="!isBeelineBranding" class="card">
        <loader-spinner
          v-if="
            isGetRadarExportsReqExecuted ||
            isUsersWithBeeProBisIdLoading ||
            isUsersWithBeeProCTNLoading ||
            isCpesLoading
          "
        ></loader-spinner>
        <div class="card-header">{{ $t('radar.title') }}</div>
        <div class="card-body table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>{{ $t('radar.name') }}</th>
                <th>{{ $t('radar.status') }}</th>
                <th>{{ $t('radar.type') }}</th>
                <th colspan="3">
                  {{ $t('radar.audience') }} ({{ $t('radar.valid') }}, {{ $t('radar.matched') }},
                  {{ $t('radar.match') }})
                </th>
                <th>{{ $t('radar.filter') }}</th>
                <th>{{ $t('radar.created') }}</th>
                <th>{{ $t('radar.updated') }}</th>
                <th v-if="areLocationsVisible">{{ $t('general.location') }}</th>
                <th v-if="!isBeelineBranding && !isNmsTest">{{ $t('radar.addresses') }}</th>
                <th v-if="isBeelineBranding || isNmsTest">{{ $t('radar.addressesOrCTN') }}</th>
                <th v-if="isNmsTest || isBeelineBranding"></th>
                <!--                <th></th>-->
              </tr>
            </thead>
            <tbody>
              <tr :key="rExport.id" v-for="rExport in radarExportList" @click="openExportModal(false, rExport)">
                <td>
                  <span
                    class="tooltip-text"
                    v-tooltip.top-center="{
                      content: `<div><b>id</b>: ${rExport.id}</div>
                        ${rExport.desc ? `<div><b>${$t('radar.description')}</b>: ${rExport.desc}</div>` : ''}`
                    }"
                  >
                    {{ rExport.name }}
                  </span>
                </td>
                <td
                  :class="{
                    'text-success': rExport.status === 'processed' || rExport.status === 'finished',
                    'text-info': rExport.status === 'running',
                    'text-muted': !['processed', 'running', 'finished'].includes(rExport.status)
                  }"
                  style="font-size: 1.15rem;"
                >
                  <i
                    v-if="rExport.auto"
                    class="fa fa-refresh"
                    v-tooltip.top-center="{ content: `<span>${$t('radar.autoPeriod')}: ${rExport.auto_period}</span>` }"
                  />
                  <i
                    class="fa"
                    :class="{
                      'fa-check-circle': rExport.status === 'processed' || rExport.status === 'finished',
                      'fa-info-circle': rExport.status === 'running',
                      'fa-circle-o': !['processed', 'running', 'finished'].includes(rExport.status)
                    }"
                    v-tooltip.top-center="{
                      content: `<span><b>${$t('radar.status')}</b>:
                      ${$te(`radar.${rExport.status}`) ? $t(`radar.${rExport.status}`) : rExport.status}</span>`
                    }"
                  />
                </td>
                <td>
                  <span
                    v-if="rExport.type === 'email'"
                    class="fa fa-envelope-o"
                    style="font-size: 1.5rem;"
                    v-tooltip.top-center="{ content: `<span>${$t('radar.email')}</span>` }"
                  />
                  <img src="/static/img/yandex.svg" v-else-if="rExport.type === 'yandex'" class="export-img" />
                  <img
                    src="/static/img/mytarget.svg"
                    v-else-if="rExport.type === 'mytarget'"
                    class="export-img export-img-mytarget"
                  />
                  <span v-else-if="rExport.type === 'beepro'">{{ $t('radar.beepro') }}</span>
                  <span v-else>{{ rExport.type }}</span>
                </td>
                <td
                  :class="[
                    rExport.state.valid_unique_quantity !== undefined && rExport.state.valid_unique_quantity > 0
                      ? 'text-success'
                      : 'text-muted'
                  ]"
                >
                  {{ rExport.state.valid_unique_quantity || '0' }}
                </td>
                <td
                  :class="[
                    rExport.state.matched_quantity !== undefined && rExport.state.matched_quantity > 0
                      ? 'text-success'
                      : 'text-muted'
                  ]"
                >
                  {{ rExport.state.matched_quantity || '0' }}
                </td>
                <td :class="[getPercentage(rExport) !== '0%' ? 'text-success' : 'text-muted']">
                  {{ getPercentage(rExport) }}
                </td>
                <td>
                  {{ $te(`radar.${rExport.filter}Visitors`) ? $t(`radar.${rExport.filter}Visitors`) : rExport.filter }}
                </td>
                <td>{{ rExport.create_at | moment }}</td>
                <td v-if="rExport.last_at">{{ rExport.last_at | moment }}</td>
                <td v-else class="text-muted">—</td>
                <td v-if="areLocationsVisible"> {{ locationName(rExport.base_location) }}</td>
                <td v-if="rExport.type !== 'beepro'">
                  <!--                  <span-->
                  <!--                    class="tooltip-text"-->
                  <!--                    v-tooltip.top-center="{-->
                  <!--                      content: `<div><b>id</b>: ${rExport.id}</div>-->
                  <!--                        ${rExport.desc ? `<div><b>${$t('radar.description')}</b>: ${rExport.desc}</div>` : ''}`-->
                  <!--                    }"-->
                  <!--                   >-->
                  <!--                    {{rExport.creds.share.length}}-->
                  <!--                  </span>-->
                  <ul v-if="rExport.creds.share.length > 0" class="items_list items_list-for-table p-0 mb-0">
                    <li v-for="(email, index) in rExport.creds.share" :data-index="index" :key="email">
                      <small :class="{ 'item-for-delete': !isDisable }" class="">
                        {{ email }}
                      </small>
                    </li>
                  </ul>
                  <small>
                    <span class="">{{ $t('radar.addressesCountCaption') }}</span>
                    : {{ rExport.creds.share.length }}
                  </small>
                </td>
                <td v-if="rExport.type === 'beepro'">
                  <!--                  <span-->
                  <!--                    class="tooltip-text"-->
                  <!--                    v-tooltip.top-center="{-->
                  <!--                      content: `<div><b>id</b>: ${rExport.id}</div>-->
                  <!--                        ${rExport.desc ? `<div><b>${$t('radar.description')}</b>: ${rExport.desc}</div>` : ''}`-->
                  <!--                    }"-->
                  <!--                   >-->
                  <!--                    {{rExport.creds.share.length}}-->
                  <!--                  </span>-->
                  <ul class="items_list items_list-for-table p-0 mb-0">
                    <li>
                      <small :class="{ 'item-for-delete': !isDisable }" class="">
                        {{ rExport.ctn }}
                      </small>
                    </li>
                  </ul>
                  <!--                  <small><span class="">{{$t('radar.addressesCountCaption')}}</span>: {{rExport.creds.share.length}}</small>-->
                </td>
                <td v-if="(isNmsTest || isBeelineBranding) && checkForBeeProInFinishedStatus(rExport)">
                  <WButton sm fullWidth
                    @click.stop="goToBeeProCabinet"
                  >
                    <span>{{ $t('radar.cabinet') }}</span>
                  </WButton>
                </td>
                <!--                <td @click.stop="createCopy(rExport)"><span class="tooltip-text">{{$t('radar.createCopy')}}</span></td>-->
              </tr>
              <tr v-if="!radarExportList.length">
                <td colspan="11" class="no-data-row">
                  <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="isBeelineBranding" class="card">
        <loader-spinner
          v-if="
            isGetRadarExportsReqExecuted ||
            isUsersWithBeeProBisIdLoading ||
            isUsersWithBeeProCTNLoading ||
            isCpesLoading
          "
        ></loader-spinner>
        <div class="card-header">{{ $t('radar.tableHeaderForBeelineForTableCaption') }}</div>
        <div class="card-body table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>{{ $t('radar.exportNameForBeelineForTableCaption') }}</th>
                <th>{{ $t('radar.statusForBeelineForTableCaption') }}</th>
                <th>{{ $t('radar.audienceForBeelineForTableCaption') }}</th>
                <th>{{ $t('radar.filter') }}</th>
                <th>{{ $t('radar.createdForBeelineForTableCaption') }}</th>
                <th>{{ $t('general.location') }}</th>
                <th>{{ $t('radar.createdForCTNForBeelineForTableCaption') }}</th>
                <th v-if="isNmsTest || isBeelineBranding"></th>
                <!--                <th></th>-->
              </tr>
            </thead>
            <tbody>
              <tr :key="rExport.id" v-for="rExport in radarExportList" @click="openExportModal(false, rExport)">
                <td>
                  <span
                    class="tooltip-text"
                    v-tooltip.top-center="{
                      content: `<div><b>id</b>: ${rExport.id}</div>
                        ${rExport.desc ? `<div><b>${$t('radar.description')}</b>: ${rExport.desc}</div>` : ''}`
                    }"
                  >
                    {{ rExport.name }}
                  </span>
                </td>
                <td
                  :class="{
                    'text-success': rExport.status === 'processed' || rExport.status === 'finished',
                    'text-info': rExport.status === 'running',
                    'text-muted': !['processed', 'running', 'finished'].includes(rExport.status)
                  }"
                  style="font-size: 1.15rem;"
                >
                  <i
                    v-if="rExport.auto"
                    class="fa fa-refresh"
                    v-tooltip.top-center="{ content: `<span>${$t('radar.autoPeriod')}: ${rExport.auto_period}</span>` }"
                  />
                  <i
                    class="fa"
                    :class="{
                      'fa-check-circle': rExport.status === 'processed' || rExport.status === 'finished',
                      'fa-info-circle': rExport.status === 'running',
                      'fa-circle-o': !['processed', 'running', 'finished'].includes(rExport.status)
                    }"
                    v-tooltip.top-center="{
                      content: `<span><b>${$t('radar.status')}</b>:
                      ${$te(`radar.${rExport.status}`) ? $t(`radar.${rExport.status}`) : rExport.status}</span>`
                    }"
                  />
                </td>
                <td
                  :class="[
                    rExport.state.valid_unique_quantity !== undefined && rExport.state.valid_unique_quantity > 0
                      ? 'text-success'
                      : 'text-muted'
                  ]"
                >
                  {{ rExport.state.valid_unique_quantity || '0' }}
                </td>

                <td>
                  {{ $te(`radar.${rExport.filter}Visitors`) ? $t(`radar.${rExport.filter}Visitors`) : rExport.filter }}
                </td>
                <td>{{ rExport.create_at | moment }}</td>
                <td>{{ locationName(rExport.base_location) }}</td>
                <td v-if="rExport.type !== 'beepro'">
                  <ul v-if="rExport.creds.share.length > 0" class="items_list items_list-for-table p-0 mb-0">
                    <li v-for="(email, index) in rExport.creds.share" :data-index="index" :key="email">
                      <small :class="{ 'item-for-delete': !isDisable }" class="">
                        {{ email }}
                      </small>
                    </li>
                  </ul>
                </td>

                <td v-if="rExport.type === 'beepro'">
                  <ul class="items_list items_list-for-table p-0 mb-0">
                    <li>
                      <small v-if="rExport.ctn !== ''" :class="{ 'item-for-delete': !isDisable }" class="">
                        {{ rExport.ctn }}
                      </small>
                      <small v-if="rExport.ctn === ''" :class="{ 'item-for-delete': !isDisable }" class="">
                        {{ $t('general.no').toLowerCase() }}
                      </small>
                    </li>
                  </ul>
                </td>

                <td v-if="(isNmsTest || isBeelineBranding) && checkForBeeProInFinishedStatus(rExport)">
                  <WButton sm fullWidth
                    @click.stop="goToBeeProCabinet"
                  >
                    <span>{{ $t('radar.cabinet') }}</span>
                  </WButton>
                </td>
                <!--                <td @click.stop="createCopy(rExport)"><span class="tooltip-text">{{$t('radar.createCopy')}}</span></td>-->
              </tr>
              <tr v-if="!radarExportList.length">
                <td colspan="11" class="no-data-row">
                  <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <modal title="RRM group settings" v-model="isExportModalOpen" class="modal-primary" closeBtn xlarge>
      <!--        <h4-->
      <!--          class="modal-title"-->
      <!--          v-if="!isNewExport && updatedGroup"-->
      <!--        >Edit {{ updatedGroup.name || 'No name' }}</h4>-->
      <h4 slot="modal-title" class="modal-title">{{ $t('radar.addNew') }}</h4>

      <!--      <div class="row">-->
      <!--        <div class="col-lg-12">-->
      <!--          <div v-if="!isNewExport && editExport.name">-->
      <!--            <ul class="list-unstyled">-->
      <!--              <li><small>id: <span class="text-muted">{{editExport.id}}</span></small></li>-->
      <!--            </ul>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="row">
        <div class="col-lg-6">
          <h5 class="mt-2">{{ $t('radar.settingsGeneral') }}</h5>

          <div class="form-group" v-if="!isBeelineBranding">
            <label for="name">{{ $t('radar.name') }}</label>
            <input
              type="text"
              :data-vv-as="$t('radar.name')"
              id="name"
              :placeholder="$t('radar.namePlaceholder')"
              name="name"
              :disabled="isDisable"
              v-validate="'required|max:32'"
              class="form-control"
              @input="changingName"
              :class="{ input: true, 'is-danger': errors.has('name') }"
              v-model="editExport.name"
            />
            <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
          </div>

          <div class="form-group" v-if="isBeelineBranding">
            <label for="name">{{ $t('radar.exportNameForBeelineForModalCaption') }}</label>
            <input
              type="text"
              :data-vv-as="$t('radar.exportNameForBeelineForModalCaption')"
              id="name"
              :placeholder="$t('radar.namePlaceholder')"
              name="name"
              :disabled="isDisable"
              v-validate="'required|max:32'"
              class="form-control"
              @input="changingName"
              :class="{ input: true, 'is-danger': errors.has('name') }"
              v-model="editExport.name"
            />
            <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
          </div>

          <div class="form-group">
            <label for="description">{{ $t('radar.description') }}</label>
            <input
              type="text"
              :data-vv-as="$t('radar.description')"
              id="description"
              :placeholder="$t('radar.descriptionPlaceholder')"
              name="description"
              :disabled="isDisable"
              class="form-control"
              v-validate="'max:64'"
              :class="{ input: true, 'is-danger': errors.has('description') }"
              v-model="editExport.desc"
            />
            <span v-show="errors.has('description')" class="help is-danger">{{ errors.first('description') }}</span>
          </div>
          <div class="form-group">
            <label for="filter">{{ $t('radar.filter') }}</label>
            <select
              v-model="editExport.filter"
              name="filter"
              id="filter"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              :disabled="isDisable"
            >
              <option v-for="filter in this.exportFilter" :key="filter" :value="filter">
                {{ $te(`radar.${filter}Visitors`) ? $t(`radar.${filter}Visitors`) : filter }}
              </option>
            </select>
          </div>

<!--          <div class="filter mb-1 cpe-select-block">-->
<!--            <Loader-spinner-small centered v-if="isCpesListFiltering"></Loader-spinner-small>-->
<!--            <label>{{ $t('radar.cpes') }}</label>-->
<!--            <div>-->
<!--              &lt;!&ndash;              <Switch-component&ndash;&gt;-->
<!--              &lt;!&ndash;                v-model="filtersForCpeList.isFiltersForCpeListEnabled"&ndash;&gt;-->
<!--              &lt;!&ndash;                :label="$t('radar.filtersForCpeListSwitcherCaption')"&ndash;&gt;-->
<!--              &lt;!&ndash;                id="cpe-filters"&ndash;&gt;-->
<!--              &lt;!&ndash;              />&ndash;&gt;-->
<!--              <div-->
<!--                :class="{-->
<!--                  'showing-filters-for-cpe-list-block-button': !filtersForCpeList.isFiltersForCpeListBlockShowing-->
<!--                }"-->
<!--                @click="toggleIsFiltersForCpeListBlockShowing"-->
<!--                role="button"-->
<!--                v-if="!isDisable"-->
<!--              >-->
<!--                <transition name="fadeforcpefiltersblockinradar">-->
<!--                  <i v-if="filtersForCpeList.isFiltersForCpeListBlockShowing" class="icon-arrow-down"></i>-->
<!--                  <i v-else class="icon-arrow-right"></i>-->
<!--                </transition>-->
<!--                {{ $t('radar.filtersForCpeListSwitcherCaption') }}-->
<!--                &nbsp;-->
<!--                <transition name="fadeforcpefiltersblockinradar">-->
<!--                  <span-->
<!--                    class="badge badge-light badge-success"-->
<!--                    v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled"-->
<!--                  >-->
<!--                    {{ $t('radar.radarFiltringForCpeListIsEnabled') }} &nbsp;-->
<!--                  </span>-->
<!--                </transition>-->
<!--                &nbsp;-->
<!--                <info :content="$t('radar.filtersForCpeListSwitcherInfoTooltip')" />-->
<!--              </div>-->
<!--            </div>-->
<!--            <transition name="fadeforcpefiltersblockinradar">-->
<!--              <div-->
<!--                v-if="filtersForCpeList.isFiltersForCpeListBlockShowing === true && !isDisable"-->
<!--                class="filters-for-cpe-list ml-2 mt-1"-->
<!--              >-->
<!--                <div class="row">-->
<!--                  <div class="col-lg-6">-->
<!--                    <div class="form-group mb-q">-->
<!--                      <label for="locationForCpeFilter">-->
<!--                        {{ $t('radar.filtersForCpeListSwitcherLocationCaption') }}-->
<!--                      </label>-->
<!--                      <select-->
<!--                        v-model="filtersForCpeList.location"-->
<!--                        name="locationForCpeFilter"-->
<!--                        id="locationForCpeFilter"-->
<!--                        :class="{ 'select-disabled': !filtersForCpeList.isFiltersForCpeListEnabled }"-->
<!--                        class="form-control"-->
<!--                        :disabled="!filtersForCpeList.isFiltersForCpeListEnabled"-->
<!--                      >-->
<!--                        <option :value="'All locations'">{{ $t('general.allLocations') }}</option>-->
<!--                        <option v-for="location in locationsList" :key="location.id" :value="location.id">-->
<!--                          {{ location.name }}-->
<!--                        </option>-->
<!--                      </select>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="col-lg-6">-->
<!--                    <div class="form-group mb-q">-->
<!--                      <label for="radarEnableStatusForCpeFilter">-->
<!--                        {{ $t('radar.radarEnableStatusForCpeFilterCaption') }}-->
<!--                      </label>-->
<!--                      <select-->
<!--                        v-model="filtersForCpeList.radarStatus"-->
<!--                        name="radarEnableStatusForCpeFilter"-->
<!--                        id="radarEnableStatusForCpeFilter"-->
<!--                        :class="{ 'select-disabled': !filtersForCpeList.isFiltersForCpeListEnabled }"-->
<!--                        class="form-control"-->
<!--                        :disabled="!filtersForCpeList.isFiltersForCpeListEnabled"-->
<!--                      >-->
<!--                        <option :value="'Any'">{{ $t('radar.radarEnableStatusForCpeFilterAny') }}</option>-->
<!--                        <option :value="'Enabled'">{{ $t('radar.radarEnableStatusForCpeFilterEnabled') }}</option>-->
<!--                        <option :value="'Disabled'">{{ $t('radar.radarEnableStatusForCpeFilterDisabled') }}</option>-->
<!--                      </select>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row">-->
<!--                  <div class="col-lg-6">-->
<!--                    <div class="form-group mt-q d-flex justify-content-between">-->
<!--                      <button-->
<!--                        :disabled="filterdCpesList.length === 0"-->
<!--                        @click="addAllFilteredCpesToSelected"-->
<!--                        style="font-weight: bold; white-space: normal; display: inline-flex;"-->
<!--                        type="button"-->
<!--                        class="btn btn-secondary btn-sm btn-block justify-content-center mt-q p-q"-->
<!--                      >-->
<!--                        <span style="font-size: 0.8em;">-->
<!--                          {{ $t('radar.selectAllCpesButtonCaption') }}-->
<!--                          <span>({{ this.filterdCpesList.length }})</span>-->
<!--                        </span>-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-lg-6">-->
<!--                    <div class="form-group mt-q d-flex justify-content-between">-->
<!--                      <button-->
<!--                        @click.stop="clearSelectedCpesList"-->
<!--                        style="font-weight: bold; white-space: normal; display: inline-flex;"-->
<!--                        type="button"-->
<!--                        class="btn btn-secondary btn-sm btn-block justify-content-center mt-q p-q"-->
<!--                      >-->
<!--                        <span class="fa fa-close"></span>-->
<!--                        <span style="font-size: 0.8em;">{{ $t('radar.cancelSelectAllCpesButtonCaption') }}</span>-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;                <div class="row">&ndash;&gt;-->
<!--                &lt;!&ndash;                  <div class="col-lg-12">&ndash;&gt;-->
<!--                &lt;!&ndash;                    <small :class="{ 'item-for-delete': !isDisable }" @click="clearFiltersForCpeList"&ndash;&gt;-->
<!--                &lt;!&ndash;                           class="text-uppercase">&ndash;&gt;-->
<!--                &lt;!&ndash;                      {{$t('radar.radarFiltringForCpeListResetFiltersButton')}}&ndash;&gt;-->
<!--                &lt;!&ndash;                      <i v-if="!isDisable" class="fa fa-remove text-danger"></i>&ndash;&gt;-->
<!--                &lt;!&ndash;                    </small>&ndash;&gt;-->
<!--                &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                &lt;!&ndash;                </div>&ndash;&gt;-->
<!--                <div class="mt-0 mb-1">-->
<!--                  <transition name="fadeforcpefiltersblockinradar">-->
<!--                    <small-->
<!--                      v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled"-->
<!--                      :class="{ 'item-for-delete': !isDisable }"-->
<!--                      @click="clearFiltersForCpeList"-->
<!--                      class=""-->
<!--                    >-->
<!--                      <i v-if="!isDisable" class="fa fa-remove text-danger"></i>-->
<!--                      {{ $t('radar.radarFiltringForCpeListResetFiltersButton') }}-->
<!--                    </small>-->
<!--                  </transition>-->
<!--                </div>-->
<!--                &lt;!&ndash;                <div class="form-group">&ndash;&gt;-->
<!--                &lt;!&ndash;                  <Switch-component&ndash;&gt;-->
<!--                &lt;!&ndash;                    v-model="filtersForCpeList.isFiltersForCpeListEnabled"&ndash;&gt;-->
<!--                &lt;!&ndash;                    :label="$t('radar.filtersForCpeListSwitcherCaption')"&ndash;&gt;-->
<!--                &lt;!&ndash;                    id="cpe-filters"&ndash;&gt;-->
<!--                &lt;!&ndash;                  />&ndash;&gt;-->
<!--                &lt;!&ndash;                </div>&ndash;&gt;-->
<!--              </div>-->
<!--            </transition>-->
<!--            <div class="w-100 text-right mb-q selected-cpes-counter-in-radar-export-modal">-->
<!--              <small class="text-muted">{{ $t('radar.selectedCPEsCaption') }}: {{ selectedCpesCount }}</small>-->
<!--            </div>-->
<!--            <div class="input-group filters__location">-->
<!--              <multiselect-->
<!--                @input="changeCPEsInMultiselectInModal"-->
<!--                :closeOnSelect="true"-->
<!--                :hideSelected="true"-->
<!--                :disabled="isDisable"-->
<!--                :multiple="true"-->
<!--                v-model="exportCpes"-->
<!--                :options="filterdCpesList || []"-->
<!--                label="name"-->
<!--                trackBy="id"-->
<!--                :selectedLabel="$t('general.multiSelectSelected')"-->
<!--                selectLabel="+"-->
<!--                deselectLabel="-"-->
<!--                :placeholder="$t('radar.cpeListPlaceholder')"-->
<!--                class="input multiselect-max-height-in-radar-export-modal"-->
<!--                :class="{ 'is-danger': isMaximumCpesWithEnabledWiFiTargetSelected === true }"-->
<!--              >-->
<!--                <template slot="option" slot-scope="props">-->
<!--                  <div class="option__desc" v-if="!showDescriptionInCpesFilter">-->
<!--                    <span-->
<!--                      class="badge-cpe badge-status ml-h tooltip-text"-->
<!--                      :class="{-->
<!--                        'badge-status__connected': props.option.connected,-->
<!--                        'badge-status__disconnected': !props.option.connected-->
<!--                      }"-->
<!--                      v-tooltip.top-center="{-->
<!--                        content: `<ul class='list-unstyled text-left m-0'>-->
<!--                                          <li><small><b>id</b>: ${props.option.id}</small></li>-->
<!--                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>-->
<!--                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>-->
<!--                                        </ul>`,-->
<!--                                        popperOptions: {-->
<!--                          modifiers: {-->
<!--                            preventOverflow: {-->
<!--                              boundariesElement: 'window'-->
<!--                            }-->
<!--                          }-->
<!--                        }-->
<!--                      }"-->
<!--                    >-->
<!--                      <span v-if="props.option.name">{{ props.option.name }}</span>-->
<!--                      <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>-->
<!--                      <span v-if="props.option.config.lbs_config.enabled === true">-->
<!--                        ({{ $t('radar.wifiTargetEnabled').toLowerCase() }})-->
<!--                      </span>-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div class="option__desc" v-if="showDescriptionInCpesFilter">-->
<!--                    <span-->
<!--                      class="badge-cpe badge-status ml-h tooltip-text"-->
<!--                      :class="{-->
<!--                        'badge-status__connected': props.option.connected,-->
<!--                        'badge-status__disconnected': !props.option.connected-->
<!--                      }"-->
<!--                      v-tooltip.top-center="{-->
<!--                        content: `<ul class='list-unstyled text-left m-0'>-->
<!--                                          <li><small><b>id</b>: ${props.option.id}</small></li>-->
<!--                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>-->
<!--                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>-->
<!--                                          <li><small>${$t('general.description')}: <b></b> ${-->
<!--                          props.option.description ? props.option.description : $t('general.no').toLowerCase()-->
<!--                        }</small></li>-->
<!--                                        </ul>`,-->
<!--                                      popperOptions: {-->
<!--                          modifiers: {-->
<!--                            preventOverflow: {-->
<!--                              boundariesElement: 'window'-->
<!--                            }-->
<!--                          }-->
<!--                        }-->
<!--                      }"-->
<!--                    >-->
<!--                      <template v-if="props.option.name">-->
<!--                        <span v-if="!props.option.description">-->
<!--                          {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}-->
<!--                        </span>-->
<!--                        <span v-if="props.option.description">-->
<!--                          {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}:-->
<!--                          {{ cutOffLongTextForCPEDescription(props.option.description) }}-->
<!--                        </span>-->
<!--                      </template>-->
<!--                      <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>-->
<!--                      <span v-if="props.option.config.lbs_config.enabled === true">-->
<!--                        ({{ $t('radar.wifiTargetEnabled').toLowerCase() }})-->
<!--                      </span>-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </template>-->
<!--                <span slot="noResult">-->
<!--                  <small v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled">-->
<!--                    {{ $t('radar.cpeListNoResultMsgWhenFilterEnabled') }}-->
<!--                  </small>-->
<!--                  <small v-else>{{ $t('radar.cpeListNoResultMsg') }}</small>-->
<!--                </span>-->
<!--                <span slot="noOptions">-->
<!--                  <small v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled">-->
<!--                    {{ $t('radar.cpeListNoOptionsMsgWhenFilterEnabled') }}-->
<!--                  </small>-->
<!--                  <small v-else>{{ $t('radar.cpeListNoOptionsMsg') }}</small>-->
<!--                </span>-->
<!--                <template slot="tag" slot-scope="{ option, search, remove }">-->
<!--                  <span-->
<!--                    :class="{ 'disabled-wifi-target-for-exports': option.config.lbs_config.enabled !== true }"-->
<!--                    class="multiselect__tag"-->
<!--                  >-->
<!--                    <span>-->
<!--                      {{ option.name }}-->
<!--                      <span v-if="option.config.lbs_config.enabled === true">-->
<!--                        ({{ $t('radar.wifiTargetEnabledShort').toLowerCase() }})-->
<!--                      </span>-->
<!--                    </span>-->
<!--                    <i-->
<!--                      tabindex="1"-->
<!--                      @keypress.enter.prevent="remove(option)"-->
<!--                      @mousedown.prevent="remove(option)"-->
<!--                      class="multiselect__tag-icon"-->
<!--                    ></i>-->
<!--                  </span>-->
<!--                </template>-->
<!--              </multiselect>-->
<!--            </div>-->
<!--            <div style="text-align: right;">-->
<!--              <a href="#" class="ml-q cpes-show-description" @click.prevent="toggleShowingDescriptionInCpesFilter">-->
<!--                {{ showDescriptionInCpesFilter ? $t('marketing.hideDescription') : $t('marketing.showDescription') }}-->
<!--              </a>-->
<!--            </div>-->
<!--            <div v-if="isMaximumCpesWithEnabledWiFiTargetSelected">-->
<!--              <div v-show="isMaximumCpesWithEnabledWiFiTargetSelected === true" class="help is-danger">-->
<!--                <span>{{ $t('radar.maximumCpesWithEnabledWiFiTargetSelectedError') }}</span>-->
<!--              </div>-->
<!--              <div v-show="isMaximumCpesWithEnabledWiFiTargetSelected === true" class="help is-danger mt-h">-->
<!--                <span-->
<!--                  @click="toggleMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal"-->
<!--                  class="maximum-Cpes-With-Enabled-WiFi-Target-Selected-Error-more"-->
<!--                  role="button"-->
<!--                >-->
<!--                  {{ $t('radar.maximumCpesWithEnabledWiFiTargetSelectedErrorMore') }}-->
<!--                </span>-->
<!--                <span class="" v-if="!showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal">-->
<!--                  <i class="icon-arrow-left"></i>-->
<!--                </span>-->
<!--                <span class="" v-if="showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal">-->
<!--                  <i class="icon-arrow-down"></i>-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

                    <div class="filter mb-1 cpe-select-block">
            <Loader-spinner-small centered v-if="isCpesListFiltering"></Loader-spinner-small>
            <label>{{ $t('radar.cpes') }}</label>
            <div>
              <!--              <Switch-component-->
              <!--                v-model="filtersForCpeList.isFiltersForCpeListEnabled"-->
              <!--                :label="$t('radar.filtersForCpeListSwitcherCaption')"-->
              <!--                id="cpe-filters"-->
              <!--              />-->
              <div
                :class="{
                  'showing-filters-for-cpe-list-block-button': !filtersForCpeList.isFiltersForCpeListBlockShowing
                }"
                @click="toggleIsFiltersForCpeListBlockShowing"
                role="button"
                v-if="!isDisable"
              >
                <transition name="fadeforcpefiltersblockinradar">
                  <i v-if="filtersForCpeList.isFiltersForCpeListBlockShowing" class="icon-arrow-down"></i>
                  <i v-else class="icon-arrow-right"></i>
                </transition>
                {{ $t('radar.filtersForCpeListSwitcherCaption') }}
                &nbsp;
                <transition name="fadeforcpefiltersblockinradar">
                  <span
                    class="badge badge-light badge-success"
                    v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled"
                  >
                    {{ $t('radar.radarFiltringForCpeListIsEnabled') }} &nbsp;
                  </span>
                </transition>
                &nbsp;
                <info :content="$t('radar.filtersForCpeListSwitcherInfoTooltip')" />
              </div>
            </div>
            <transition name="fadeforcpefiltersblockinradar">
              <div
                v-if="filtersForCpeList.isFiltersForCpeListBlockShowing === true && !isDisable"
                class="filters-for-cpe-list ml-2 mt-1"
                style="position: relative"
              >
                <loader-spinner-small v-if="showSpinnerOverCpeFiltersBlockInModal" centered></loader-spinner-small>
                <div class="row">
                  <div class="col-lg-6" v-if="areLocationsVisible">
                    <div class="form-group mb-q">
                      <label for="locationForCpeFilter">
                        {{ $t('radar.filtersForCpeListSwitcherLocationCaption') }}
                      </label>
                      <select
                        @change="selectLocationInCpesFilterForModal"
                        v-model="filtersForCpeList.location"
                        name="locationForCpeFilter"
                        id="locationForCpeFilter"
                        :class="{ 'select-disabled': !filtersForCpeList.isFiltersForCpeListEnabled }"
                        class="form-control"
                        :disabled="!filtersForCpeList.isFiltersForCpeListEnabled"
                      >
                        <option :value="'All locations'">{{ $t('general.allLocations') }}</option>
                        <option v-for="location in locationsListForCpeFilterInModal" :key="location.id" :value="location.id">
                          {{ location.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group mb-q">
                      <label for="radarEnableStatusForCpeFilter">
                        {{ $t('radar.radarEnableStatusForCpeFilterCaption') }}
                      </label>
                      <select
                        @change="selectWifiTargetFilterInCpesFilterForModal"
                        v-model="filtersForCpeList.radarStatus"
                        name="radarEnableStatusForCpeFilter"
                        id="radarEnableStatusForCpeFilter"
                        :class="{ 'select-disabled': !filtersForCpeList.isFiltersForCpeListEnabled }"
                        class="form-control"
                        :disabled="!filtersForCpeList.isFiltersForCpeListEnabled"
                      >
                        <option :value="'Any'">{{ $t('radar.radarEnableStatusForCpeFilterAny') }}</option>
                        <option :value="'Enabled'">{{ $t('radar.radarEnableStatusForCpeFilterEnabled') }}</option>
                        <option :value="'Disabled'">{{ $t('radar.radarEnableStatusForCpeFilterDisabled') }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group mt-q d-flex justify-content-between">
                      <WButton secondary sm fullWidth
                        customClass="mt-q p-q"
                        @click="addAllFilteredCpesToSelected"
                        :disabled="cpeListLengthForSelectedInModalLocation === 0"
                      >
                        <span>
                          {{ $t('radar.selectAllCpesButtonCaption') }}
                          <span>({{ cpeListLengthForSelectedInModalLocation }})</span>
                        </span>
                      </WButton>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group mt-q d-flex justify-content-between">
                      <WButton secondary sm fullWidth
                        customClass="mt-q p-q"
                        @click.stop="clearSelectedCpesList"
                      >
                        <span class="fa fa-close"></span>
                        <span>{{ $t('radar.cancelSelectAllCpesButtonCaption') }}</span>
                      </WButton>
                    </div>
                  </div>
                </div>
                <!--                <div class="row">-->
                <!--                  <div class="col-lg-12">-->
                <!--                    <small :class="{ 'item-for-delete': !isDisable }" @click="clearFiltersForCpeList"-->
                <!--                           class="text-uppercase">-->
                <!--                      {{$t('radar.radarFiltringForCpeListResetFiltersButton')}}-->
                <!--                      <i v-if="!isDisable" class="fa fa-remove text-danger"></i>-->
                <!--                    </small>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="mt-0 mb-1">
                  <transition name="fadeforcpefiltersblockinradar">
                    <small
                      v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled"
                      :class="{ 'item-for-delete': !isDisable }"
                      @click="clearFiltersForCpeList"
                      class=""
                    >
                      <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                      {{ $t('radar.radarFiltringForCpeListResetFiltersButton') }}
                    </small>
                  </transition>
                </div>
                <!--                <div class="form-group">-->
                <!--                  <Switch-component-->
                <!--                    v-model="filtersForCpeList.isFiltersForCpeListEnabled"-->
                <!--                    :label="$t('radar.filtersForCpeListSwitcherCaption')"-->
                <!--                    id="cpe-filters"-->
                <!--                  />-->
                <!--                </div>-->
              </div>
            </transition>
            <div class="w-100 text-right mb-q selected-cpes-counter-in-radar-export-modal">
              <small class="text-muted">{{ $t('radar.selectedCPEsCaption') }}: {{ selectedCpesCount }}</small>
            </div>
            <div class="input-group filters__location">
              <multiselect
                @search-change="asyncSearchCpeWhenSearchChangeForMultiedit"
                @open="asyncSearchCpeWhenOpenSelectorListForMultiedit"
                :loading="loadingDataForCpeListForMultiedit"
                @input="changeCPEsInMultiselectInModal"
                :closeOnSelect="true"
                :hideSelected="true"
                :disabled="isDisable"
                :multiple="true"
                v-model="exportCpes"
                :options="cpeListForCpeSearchForMultiedit || []"
                label="name"
                trackBy="id"
                :selectedLabel="$t('general.multiSelectSelected')"
                selectLabel="+"
                deselectLabel="-"
                :placeholder="$t('radar.cpeListPlaceholder')"
                class="input multiselect-max-height-in-radar-export-modal"
                :class="{ 'is-danger': isMaximumCpesWithEnabledWiFiTargetSelected === true }"
              >
                <template slot="option" slot-scope="props">
                  <div class="option__desc" v-if="!showDescriptionInCpesFilter">
                    <span
                      class="badge-cpe badge-status ml-h tooltip-text"
                      :class="{
                        'badge-status__connected': props.option.connected,
                        'badge-status__disconnected': !props.option.connected
                      }"
                      v-tooltip.top-center="{
                        content: `<ul class='list-unstyled text-left m-0'>
                                          <li><small><b>id</b>: ${props.option.id}</small></li>
                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>
                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>
                                        </ul>`,
                                        popperOptions: {
                          modifiers: {
                            preventOverflow: {
                              boundariesElement: 'window'
                            }
                          }
                        }
                      }"
                    >
                      <span v-if="props.option.name">{{ props.option.name }}</span>
                      <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                      <span v-if="props.option.config.lbs_config.enabled === true">
                        ({{ $t('radar.wifiTargetEnabled').toLowerCase() }})
                      </span>
                    </span>
                  </div>
                  <div class="option__desc" v-if="showDescriptionInCpesFilter">
                    <span
                      class="badge-cpe badge-status ml-h tooltip-text"
                      :class="{
                        'badge-status__connected': props.option.connected,
                        'badge-status__disconnected': !props.option.connected
                      }"
                      v-tooltip.top-center="{
                        content: `<ul class='list-unstyled text-left m-0'>
                                          <li><small><b>id</b>: ${props.option.id}</small></li>
                                          <li><small><b>mac</b>: ${props.option.state.network.macaddr}</small></li>
                                          <li><small><b>ip</b>: ${props.option.state.network.ipaddr}</small></li>
                                          <li><small>${$t('general.description')}: <b></b> ${
                          props.option.description ? props.option.description : $t('general.no').toLowerCase()
                        }</small></li>
                                        </ul>`,
                                      popperOptions: {
                          modifiers: {
                            preventOverflow: {
                              boundariesElement: 'window'
                            }
                          }
                        }
                      }"
                    >
                      <template v-if="props.option.name">
                        <span v-if="!props.option.description">
                          {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}
                        </span>
                        <span v-if="props.option.description">
                          {{ props.option.name.split(charactersForCpeListSeparateNameFromDescription)[0] }}:
                          {{ cutOffLongTextForCPEDescription(props.option.description) }}
                        </span>
                      </template>
                      <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                      <span v-if="props.option.config.lbs_config.enabled === true">
                        ({{ $t('radar.wifiTargetEnabled').toLowerCase() }})
                      </span>
                    </span>
                  </div>
                </template>
                <span slot="noResult">
                  <small v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled">
                    {{ $t('radar.cpeListNoResultMsgWhenFilterEnabled') }}
                  </small>
                  <small v-else>{{ $t('radar.cpeListNoResultMsg') }}</small>
                </span>
                <span slot="noOptions">
                  <small v-if="isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled">
                    {{ $t('radar.cpeListNoOptionsMsgWhenFilterEnabled') }}
                  </small>
                  <small v-else>{{ $t('radar.cpeListNoOptionsMsg') }}</small>
                </span>
                <template slot="tag" slot-scope="{ option, search, remove }">
                  <span
                    :class="{ 'disabled-wifi-target-for-exports': option.config.lbs_config.enabled !== true }"
                    class="multiselect__tag"
                  >
                    <span>
                      {{ option.name }}
                      <span v-if="option.config.lbs_config.enabled === true">
                        ({{ $t('radar.wifiTargetEnabledShort').toLowerCase() }})
                      </span>
                    </span>
                    <i
                      tabindex="1"
                      @keypress.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon"
                    ></i>
                  </span>
                </template>
              </multiselect>
            </div>
            <div style="text-align: right;">
              <a href="#" class="ml-q cpes-show-description" @click.prevent="toggleShowingDescriptionInCpesFilter">
                {{ showDescriptionInCpesFilter ? $t('marketing.hideDescription') : $t('marketing.showDescription') }}
              </a>
            </div>
            <div v-if="isMaximumCpesWithEnabledWiFiTargetSelected">
              <div v-show="isMaximumCpesWithEnabledWiFiTargetSelected === true" class="help is-danger">
                <span>{{ $t('radar.maximumCpesWithEnabledWiFiTargetSelectedError') }}</span>
              </div>
              <div v-show="isMaximumCpesWithEnabledWiFiTargetSelected === true" class="help is-danger mt-h">
                <span
                  @click="toggleMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal"
                  class="maximum-Cpes-With-Enabled-WiFi-Target-Selected-Error-more"
                  role="button"
                >
                  {{ $t('radar.maximumCpesWithEnabledWiFiTargetSelectedErrorMore') }}
                </span>
                <span class="" v-if="!showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal">
                  <i class="icon-arrow-left"></i>
                </span>
                <span class="" v-if="showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal">
                  <i class="icon-arrow-down"></i>
                </span>
              </div>
            </div>
          </div>



          <transition name="interface" mode="out-in">
            <div v-if="showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal">
              <Alert
                v-if="showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal"
                class="alert-info"
                @alert-closed="showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal = false"
              >
                <template #text>
                  <div>
                    <!--                                      <div>-->
                    <!--                    <p>-->
                    <!--                      {{$t('radar.maximumCpesWithEnabledWiFiTargetSelectedErrorMoreModalHeader')}}: </p>-->
                    <!--                  </div>-->
                    <div v-if="isRuLocale">
                      <ul>
                        <li>
                          <b>В диапазоне до одного месяца</b>
                          : любое количество ТД (с включенным Wi-FI Таргет)
                        </li>
                        <li>
                          <b>В диапазоне от одного месяца до двух месяцев</b>
                          : не более 50 ТД (с включенным Wi-FI Таргет)
                        </li>
                        <li>
                          <b>В диапазоне от двух до трех месяцев</b>
                          : не более 30 ТД (с включенным Wi-FI Таргет)
                        </li>
                      </ul>
                    </div>
                    <div v-else>
                      <ul>
                        <li>
                          <b>In the range of up to one month</b>
                          : Any number of APs (with Wi-FI Target enabled)
                        </li>
                        <li>
                          <b>Ranging from one month to two months</b>
                          : No more than 50 APs (with Wi-FI Target enabled)
                        </li>
                        <li>
                          <b>Ranging from two month to three months</b>
                          : No more than 30 APs (with Wi-FI Target enabled)
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
              </Alert>
            </div>
          </transition>
          <transition name="interface" mode="out-in">
            <div v-if="showingWifiTargetDisabledOnAllSelectedCPESMsg">
              <Alert
                v-if="showingWifiTargetDisabledOnAllSelectedCPESMsg"
                closable
                class="alert-warning"
                @alert-closed="hideWifiTargetDisabledOnAllSelectedCPESMsg"
              >
                <template #text>
                  <span class="mr-h">{{ $t('radar.wifiTargetDisabledOnAllSelectedCPES') }}</span>
                </template>
              </Alert>
            </div>
          </transition>
          <div v-if="!isBeelineBranding && areLocationsVisible" class="form-group">
            <label for="company-in-modal">{{ $t('general.company') }}</label>
            <select
              v-model="editExportCompany"
              @change="changeCompanyInModal"
              name="company-in-modal"
              id="company-in-modal"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              :disabled="isDisable"
            >
              <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>
              <option v-for="company in companys" :key="company.name" :value="company">
                {{ company.name }}
              </option>
            </select>
          </div>

          <div class="form-group" v-if="editExportCompany === 'All companys'" v-show="areLocationsVisible">
            <label for="location">{{ $t('general.location') }}</label>
            <select
              v-model="editExport.base_location"
              name="location"
              id="location"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              :disabled="isDisable"
            >
              <option v-for="location in locationsList" :key="location.id" :value="location.id">
                {{ location.name }}
              </option>
            </select>
          </div>
          <div class="form-group" v-else v-show="areLocationsVisible">
            <label for="location">{{ $t('general.location') }}</label>
            <select
              v-model="editExport.base_location"
              name="location"
              id="location"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              :disabled="isDisable"
            >
              <option
                v-for="location in locationsObjbyIds(editExportCompany.locations)"
                :key="location.id"
                :value="location.id"
              >
                {{ location.name }}
              </option>
            </select>
          </div>

          <!--          <div class="form-group">-->
          <!--            <label for="location">{{ $t('general.location') }}</label>-->
          <!--            <select-->
          <!--              v-model="editExport.base_location"-->
          <!--              name="location"-->
          <!--              id="location"-->
          <!--              :class="{ 'select-disabled': isDisable }"-->
          <!--              class="form-control"-->
          <!--              :disabled="isDisable"-->
          <!--            >-->
          <!--              <option v-for="location in locationsList" :key="location.id" :value="location.id">-->
          <!--                {{ location.name }}-->
          <!--              </option>-->
          <!--            </select>-->
          <!--          </div>-->
          <!--          <div class="form-group">-->
          <!--            <label for="company-in-modal">{{ $t('general.company') }}</label>-->
          <!--            <select-->
          <!--              v-model="editExport.base_location"-->
          <!--              name="company-in-modal"-->
          <!--              id="company-in-modal"-->
          <!--              :class="{ 'select-disabled': isDisable }"-->
          <!--              class="form-control"-->
          <!--              :disabled="isDisable"-->
          <!--            >-->
          <!--              <option v-for="company in companyList" :key="company.name" :value="company.locationId">-->
          <!--                {{ company.name }}-->
          <!--              </option>-->
          <!--            </select>-->
          <!--          </div>-->

          <span>{{ $t('radar.period') }}</span>

          <div class>
            <div class="d-flex mt-h" :class="{ 'mb-1': !isShowingDateRangeLimitExceededError }">
              <date-picker
                :show-error="isShowingDateRangeLimitExceededError"
                enable-max-date-range-limit
                v-if="!isDisable && isShowDatepickerInModal"
                v-model="drPickerDateForExport"
                :onChange="handleDateChangeForRating.bind(this)"
                @isDateRangeLimitEnabledAndExceeded="dateRangeLimitEnabledAndExceeded"
              />
            </div>
            <div v-show="isShowingDateRangeLimitExceededError === true" class="mb-h mb-1">
              <span v-show="isShowingDateRangeLimitExceededError === true" class="help is-danger">
                {{ $t('general.dateRangeLimitExceededError') }}
                {{ moment.duration(dateRangeLimitExceededErrorRangeLimit).humanize() }}
              </span>
            </div>

            <div v-if="!isBeelineBranding" class="form-group mt-1 mb-q">
              <Switch-component
                v-model="isContinuously"
                :disabled="isDisable"
                @change="changeContinuously"
                :label="$t('radar.continuously')"
                id="radar-continuously"
              />
            </div>
          </div>
        </div>
        <!--        правая колонка в модалке для обычной сборки -->
        <div v-if="!isBeelineBranding" class="col-lg-6">
          <h5 class="mt-2">{{ $t('radar.settingsExport') }}</h5>
          <div v-if="!isBeelineBranding" class="form-group">
            <label for="type">{{ $t('radar.type') }}</label>
            <select
              v-model="editExport.type"
              name="type"
              id="type"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              :disabled="isDisable"
              @change="changeType(editExport.type)"
            >
              <option v-for="type in this.exportType" :key="type" :value="type">
                {{ $te(`radar.${type}`) ? $t(`radar.${type}`) : type }}
              </option>
              <option v-if="isNmsTest" kay="beepro" value="beepro">
                {{ $t(`radar.beepro`) }}
              </option>
            </select>
            <p v-if="editExport.type === 'yandex'" class="text-muted">{{ $t('radar.yandexCaption') }}</p>
          </div>

          <div v-if="isBeelineBranding && editExport.type !== 'beepro'" class="form-group">
            <label for="type">{{ $t('radar.type') }}</label>
            <select
              v-model="editExport.type"
              name="type"
              id="type"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              @change="changeForBeeline(editExport.type)"
            >
              <option v-for="type in this.exportTypeForBeeline" :key="type" :value="type">
                {{ $te(`radar.${type}`) ? $t(`radar.${type}`) : type }}
              </option>
            </select>
          </div>

          <div v-if="isBeelineBranding && editExport.type === 'beepro'" class="form-group">
            <label for="type">{{ $t('radar.type') }}:</label>
            <h4 id="type">{{ $t('radar.beepro') }}</h4>
            <!--            <select-->
            <!--              v-model="editExport.type"-->
            <!--              name="type"-->
            <!--              id="type"-->
            <!--              :class="{ 'select-disabled': isDisable }"-->
            <!--              class="form-control"-->
            <!--              :disabled="true"-->
            <!--              @change="changeForBeeline(editExport.type)"-->
            <!--            >-->
            <!--              <option v-for="type in this.exportTypeForBeeline" :key="type" :value="type">-->
            <!--                {{ $te(`radar.${type}`) ? $t(`radar.${type}`) : type }}-->
            <!--              </option>-->
            <!--            </select>-->
          </div>

          <div
            v-if="editExport.type === 'email'"
            class="mb-2"
            :class="{ 'form-group': isBeelineBranding, 'mt-0': isBeelineBranding, 'mt-2': !isBeelineBranding }"
          >
            <div v-if="!isBeelineBranding">
              <h6>{{ $t('radar.settingsEmail') }}</h6>
            </div>
            <div v-else>
              <!--              <label>{{ $t('radar.settingsEmail') }}</label>-->
              <h6>{{ $t('radar.settingsEmail') }}</h6>
            </div>
            <div class="form-group mb-q" v-if="editExport.creds">
              <label for="subject">{{ $t('radar.subject') }}</label>
              <input
                type="text"
                id="subject"
                :data-vv-as="$t('radar.subject')"
                :placeholder="$t('radar.subjectPlaceholder')"
                name="subject"
                :disabled="isDisable"
                class="form-control"
                v-validate="'required|max:50'"
                :class="{ input: true, 'is-danger': errors.has('subject') }"
                v-model="editExport.creds.subject"
              />
              <span v-show="errors.has('subject')" class="help is-danger">{{ errors.first('subject') }}</span>
            </div>

            <div v-if="!isBeelineBranding" class="form-group">
              <label for="format">{{ $t('radar.format') }}</label>
              <select
                v-model="editExport.format"
                name="format"
                id="format"
                :class="{ 'select-disabled': isDisable }"
                class="form-control"
                :disabled="isDisable"
              >
                <option v-for="format in this.exportFormat" :key="format" :value="format">{{ format }}</option>
              </select>
            </div>
          </div>

          <div
            v-if="editExport.type === 'beepro'"
            class="mb-1"
            :class="{ 'form-group': isBeelineBranding, 'mt-0': isBeelineBranding, 'mt-2': !isBeelineBranding }"
          >
            <div v-if="!isBeelineBranding">
              <h6>{{ $t('radar.settingsBeepro') }}</h6>
            </div>
            <div v-else>
              <!--              <label>{{ $t('radar.settingsEmail') }}</label>-->
              <h6>{{ $t('radar.settingsBeepro') }}</h6>
            </div>
            <!--            <div class="form-group mb-q" v-if="editExport.creds">-->
            <!--              <label for="subject">{{ $t('radar.subject') }}</label>-->
            <!--              <input-->
            <!--                type="text"-->
            <!--                id="subject"-->
            <!--                :data-vv-as="$t('radar.subject')"-->
            <!--                :placeholder="$t('radar.subjectPlaceholder')"-->
            <!--                name="subject"-->
            <!--                :disabled="isDisable"-->
            <!--                class="form-control"-->
            <!--                v-validate="'required|max:50'"-->
            <!--                :class="{ input: true, 'is-danger': errors.has('subject') }"-->
            <!--                v-model="editExport.creds.subject"-->
            <!--              />-->
            <!--              <span v-show="errors.has('subject')" class="help is-danger">{{ errors.first('subject') }}</span>-->
            <!--            </div>-->

            <!--            <div v-if="isNmsTest && editExport.type==='beepro'" class="form-group">-->
            <!--              <label for="format">{{ $t('radar.format') }}</label>-->
            <!--              <select-->
            <!--                v-model="editExport.format"-->
            <!--                name="format"-->
            <!--                id="format"-->
            <!--                :class="{ 'select-disabled': isDisable }"-->
            <!--                class="form-control"-->
            <!--                :disabled="isDisable"-->
            <!--              >-->
            <!--                <option v-for="format in this.exportFormat" :key="format" :value="format">{{ format }}</option>-->
            <!--              </select>-->
            <!--            </div>-->
          </div>

          <div class="form-group mt-1" v-if="!isBeelineBranding && editExport.type !== 'beepro'">
            <Switch-component
              v-model="editExport.hash"
              :disabled="isDisable || editExport.type !== 'email'"
              :label="$t('radar.hash')"
              id="radar-hash"
            />
            &nbsp;
            <info :content="$t('radar.hashInfo')" />
          </div>

          <div class="form-group mt-1" v-if="isBeelineBranding && editExport.type !== 'beepro'">
            <Switch-component
              v-model="editExport.hash"
              :disabled="isDisable || editExport.type !== 'email'"
              :label="$t('radar.hash')"
              id="radar-hash"
            />
            &nbsp;
            <info :content="$t('radar.hashInfo')" />
          </div>

          <div class="form-group mt-1 mb-q" v-if="!isBeelineBranding && editExport.type !== 'beepro'">
            <Switch-component
              v-model="editExport.auto"
              :disabled="isDisable"
              :label="$t('radar.auto')"
              id="radar-auto"
            />
          </div>
          <div class="form-group mt-1 mb-q" v-if="isBeelineBranding && editExport.type !== 'beepro'">
            <Switch-component
              v-model="editExport.auto"
              :disabled="isDisable"
              :label="$t('radar.auto')"
              id="radar-auto"
            />
          </div>

          <div v-if="editExport.auto" class>
            <div class="form-group" v-if="editExport.auto && editExport.type !== 'yandex'">
              <label for="auto_period">{{ $t('radar.autoPeriod') }}</label>
              <input
                type="text"
                id="auto_period"
                :data-vv-as="$t('radar.autoPeriod')"
                :placeholder="$t('radar.autoPlaceholder')"
                name="auto_period"
                :disabled="isDisable"
                v-validate="'required|numeric'"
                class="form-control"
                :class="{ input: true, 'is-danger': errors.has('auto_period') }"
                v-model.number="editExport.auto_period"
              />
              <span v-show="errors.has('auto_period')" class="help is-danger">{{ errors.first('auto_period') }}</span>
            </div>
          </div>

          <div v-if="editExport.creds && editExport.type !== 'beepro'" class="mt-2">
            <div class="form-group mb-h mt-2">
              <label for="email">{{ $t('radar.share') }}</label>
              <div class="d-flex w-100">
                <input
                  type="text"
                  :disabled="isDisable"
                  name="email"
                  class="form-control input-field-near-button-in-wifi-target"
                  placeholder="user@domain.com"
                  id="email"
                  @input="enableSaveChanges = true"
                  v-validate="'emailForReports'"
                  @keyup.13="addEmail"
                  data-vv-validate-on="input"
                  v-model="newShareEmail"
                  :class="{ input: true, 'is-danger': errors.has('email') }"
                />
                <span>
                  <WButton success
                    @click="addEmail"
                    :disabled="newShareEmail == '' || errors.has('email')"
                  >
                    {{ $t('general.add') }}
                  </WButton>
                </span>
              </div>
            </div>

            <div class="ml-h mb-q" v-if="editExport.creds.share.length">
              <small>
                <strong>{{ $t('radar.items') }}: {{ editExport.creds.share.length }}</strong>
              </small>
            </div>
            <ul class="items_list p-0 ml-h mb-2">
              <li v-for="(email, index) in editExport.creds.share" :data-index="index" :key="email">
                <small :class="{ 'item-for-delete': !isDisable }" @click="removeEmail" class="text-uppercase">
                  {{ email }}
                  <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                </small>
              </li>
            </ul>
          </div>

          <!--          <div v-if="editExport.creds && editExport.type === 'beepro'" class="mt-0">-->
          <!--            <div class="form-group mb-h mt-0">-->
          <!--              <label for="businessId">{{ $t('radar.businessIdsCaption') }}</label>-->
          <!--&lt;!&ndash;              <div class="d-flex w-100">&ndash;&gt;-->
          <!--&lt;!&ndash;                <input&ndash;&gt;-->
          <!--&lt;!&ndash;                  type="number"&ndash;&gt;-->
          <!--&lt;!&ndash;                  :disabled="isDisable"&ndash;&gt;-->
          <!--&lt;!&ndash;                  name="businessIds"&ndash;&gt;-->
          <!--&lt;!&ndash;                  class="form-control"&ndash;&gt;-->
          <!--&lt;!&ndash;                  placeholder="Business Id"&ndash;&gt;-->
          <!--&lt;!&ndash;                  id="businessId"&ndash;&gt;-->
          <!--&lt;!&ndash;                  @input="enableSaveChanges = true"&ndash;&gt;-->
          <!--&lt;!&ndash;                  @keyup.13="addBusinessId"&ndash;&gt;-->
          <!--&lt;!&ndash;                  data-vv-validate-on="input"&ndash;&gt;-->
          <!--&lt;!&ndash;                  v-model="newShareBusinessId"&ndash;&gt;-->
          <!--&lt;!&ndash;                  :class="{ input: true, 'is-danger': errors.has('email') }"&ndash;&gt;-->
          <!--&lt;!&ndash;                />&ndash;&gt;-->
          <!--&lt;!&ndash;                <span class>&ndash;&gt;-->
          <!--&lt;!&ndash;                  <button&ndash;&gt;-->
          <!--&lt;!&ndash;                    class="btn btn-success mb-0"&ndash;&gt;-->
          <!--&lt;!&ndash;                    type="button"&ndash;&gt;-->
          <!--&lt;!&ndash;                    @click="addBusinessId"&ndash;&gt;-->
          <!--&lt;!&ndash;                    :disabled="newShareBusinessId == '' || errors.has('email')"&ndash;&gt;-->
          <!--&lt;!&ndash;                  >&ndash;&gt;-->
          <!--&lt;!&ndash;                    {{ $t('general.add') }}&ndash;&gt;-->
          <!--&lt;!&ndash;                  </button>&ndash;&gt;-->
          <!--&lt;!&ndash;                </span>&ndash;&gt;-->
          <!--&lt;!&ndash;              </div>&ndash;&gt;-->
          <!--            </div>-->
          <!--            <div class="ml mt-h">-->
          <!--              <small>-->
          <!--                <strong>{{ $t('radar.user') }}:</strong>-->
          <!--              </small>-->
          <!--            </div>-->
          <!--            <multiselect-->
          <!--              id="businessId"-->
          <!--              @input="changeUserWithBusinessIdInSelector"-->
          <!--              :closeOnSelect="true"-->
          <!--              :hideSelected="true"-->
          <!--              :disabled="isDisable"-->
          <!--              :multiple="false"-->
          <!--              v-model="newShareBusinessIdSelectedObject"-->
          <!--              :options="usersWithBeeProBisId || []"-->
          <!--              label="username"-->
          <!--              trackBy="bee_pro_bis_id"-->
          <!--              :selectedLabel="$t('general.multiSelectSelected')"-->
          <!--              selectLabel="+"-->
          <!--              deselectLabel="-"-->
          <!--              :placeholder="$t('radar.usersWithBusinessIdsPlaceholder')"-->
          <!--            ></multiselect>-->
          <!--            <div class="ml mb-q" v-if="isShowingBisIdError">-->
          <!--              <small>-->
          <!--                <strong style="color:red">{{$t('radar.businessIdErrorShow')}}</strong>-->
          <!--              </small>-->
          <!--            </div>-->

          <!--            <div class="ml mt-h" v-if="editExport.creds.share.length">-->
          <!--              <small>-->
          <!--                <strong>{{ $t('radar.selectedBusinessIdsCaption') }}:</strong>-->
          <!--              </small>-->
          <!--            </div>-->
          <!--            <ul class="items_list p-0 mb-2">-->
          <!--              <li v-for="(businessId, index) in editExport.creds.share" :data-index="index" :key="businessId">-->
          <!--                <small :class="{ 'item-for-delete': !isDisable }" @click="removeBusinessId" class="text-uppercase">-->
          <!--                  {{ businessId }}-->
          <!--                  <i v-if="!isDisable" class="fa fa-remove text-danger"></i>-->
          <!--                </small>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </div>-->

          <div v-if="editExport.type === 'beepro'" class="mt-0 mb-0">
            <div class="form-group mb-0 mt-0">
              <label class="mb-0" for="ctnField">{{ $t('radar.ctnCaption') }}</label>
              <!--              <div class="d-flex w-100">-->
              <!--                <input-->
              <!--                  type="number"-->
              <!--                  :disabled="isDisable"-->
              <!--                  name="businessIds"-->
              <!--                  class="form-control"-->
              <!--                  placeholder="Business Id"-->
              <!--                  id="businessId"-->
              <!--                  @input="enableSaveChanges = true"-->
              <!--                  @keyup.13="addBusinessId"-->
              <!--                  data-vv-validate-on="input"-->
              <!--                  v-model="newShareBusinessId"-->
              <!--                  :class="{ input: true, 'is-danger': errors.has('email') }"-->
              <!--                />-->
              <!--                <span class>-->
              <!--                  <button-->
              <!--                    class="btn btn-success mb-0"-->
              <!--                    type="button"-->
              <!--                    @click="addBusinessId"-->
              <!--                    :disabled="newShareBusinessId == '' || errors.has('email')"-->
              <!--                  >-->
              <!--                    {{ $t('general.add') }}-->
              <!--                  </button>-->
              <!--                </span>-->
              <!--              </div>-->
            </div>
            <div class="ml mt-h">
              <small>
                <strong>{{ $t('radar.user') }}:</strong>
              </small>
            </div>
            <multiselect
              id="ctnField"
              @input="changeUserWithCtnInSelector"
              :closeOnSelect="true"
              :hideSelected="true"
              :disabled="isDisable"
              :multiple="false"
              v-model="newShareBusinessIdSelectedObject"
              :options="usersWithBeeProCTN || []"
              label="username"
              trackBy="bee_pro_ctn"
              :selectedLabel="$t('general.multiSelectSelected')"
              selectLabel="+"
              deselectLabel="-"
              :placeholder="$t('radar.usersWithBusinessIdsPlaceholder')"
            ></multiselect>
            <div class="ml mb-q" v-if="isShowingCTNError">
              <small>
                <strong style="color: red;">{{ $t('radar.ctnErrorShow') }}</strong>
              </small>
            </div>

            <div class="ml mt-h">
              <small>
                <strong>{{ $t('radar.selectedCTNCaption') }}:</strong>
              </small>
            </div>
            <ul class="items_list p-0 mb-2" v-if="this.editExport.ctn != ''">
              <small
                v-if="this.editExport.ctn != ''"
                :class="{ 'item-for-delete': !isDisable }"
                @click="removeCTN"
                class="text-uppercase"
              >
                {{ this.editExport.ctn }}
                <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
              </small>
            </ul>
          </div>
        </div>
        <!--        правая колонка в модалке для beeline сборки-->
        <div v-if="isBeelineBranding" class="col-lg-6">
          <h5 class="mt-2">{{ $t('radar.settingsForBeelineForModalCaption') }}</h5>

          <div v-if="isBeelineBranding && editExport.type !== 'beepro'" class="form-group">
            <label for="type">{{ $t('radar.type') }}</label>
            <select
              v-model="editExport.type"
              name="type"
              id="type"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              @change="changeForBeeline(editExport.type)"
            >
              <option v-for="type in this.exportTypeForBeeline" :key="type" :value="type">
                {{ $te(`radar.${type}`) ? $t(`radar.${type}`) : type }}
              </option>
            </select>
          </div>

          <!--          <div v-if="isBeelineBranding && editExport.type === 'beepro'" class="form-group ">-->
          <!--&lt;!&ndash;            <label for="type">{{ $t('radar.type') }}:</label>&ndash;&gt;-->
          <!--&lt;!&ndash;            <h4 id="type">{{$t('radar.beepro')}}</h4>&ndash;&gt;-->
          <!--&lt;!&ndash;            <select&ndash;&gt;-->
          <!--&lt;!&ndash;              v-model="editExport.type"&ndash;&gt;-->
          <!--&lt;!&ndash;              name="type"&ndash;&gt;-->
          <!--&lt;!&ndash;              id="type"&ndash;&gt;-->
          <!--&lt;!&ndash;              :class="{ 'select-disabled': isDisable }"&ndash;&gt;-->
          <!--&lt;!&ndash;              class="form-control"&ndash;&gt;-->
          <!--&lt;!&ndash;              :disabled="true"&ndash;&gt;-->
          <!--&lt;!&ndash;              @change="changeForBeeline(editExport.type)"&ndash;&gt;-->
          <!--&lt;!&ndash;            >&ndash;&gt;-->
          <!--&lt;!&ndash;              <option v-for="type in this.exportTypeForBeeline" :key="type" :value="type">&ndash;&gt;-->
          <!--&lt;!&ndash;                {{ $te(`radar.${type}`) ? $t(`radar.${type}`) : type }}&ndash;&gt;-->
          <!--&lt;!&ndash;              </option>&ndash;&gt;-->
          <!--&lt;!&ndash;            </select>&ndash;&gt;-->
          <!--          </div>-->

          <div
            v-if="editExport.type === 'email'"
            class="mb-2"
            :class="{ 'form-group': isBeelineBranding, 'mt-0': isBeelineBranding, 'mt-2': !isBeelineBranding }"
          >
            <div v-if="!isBeelineBranding">
              <h6>{{ $t('radar.settingsEmail') }}</h6>
            </div>
            <div v-else>
              <!--              <label>{{ $t('radar.settingsEmail') }}</label>-->
              <h6>{{ $t('radar.settingsEmail') }}</h6>
            </div>
            <div class="form-group mb-q" v-if="editExport.creds">
              <label for="subject">{{ $t('radar.subject') }}</label>
              <input
                type="text"
                id="subject"
                :data-vv-as="$t('radar.subject')"
                :placeholder="$t('radar.subjectPlaceholder')"
                name="subject"
                :disabled="isDisable"
                class="form-control"
                v-validate="'required|max:50'"
                :class="{ input: true, 'is-danger': errors.has('subject') }"
                v-model="editExport.creds.subject"
              />
              <span v-show="errors.has('subject')" class="help is-danger">{{ errors.first('subject') }}</span>
            </div>

            <div v-if="!isBeelineBranding" class="form-group">
              <label for="format">{{ $t('radar.format') }}</label>
              <select
                v-model="editExport.format"
                name="format"
                id="format"
                :class="{ 'select-disabled': isDisable }"
                class="form-control"
                :disabled="isDisable"
              >
                <option v-for="format in this.exportFormat" :key="format" :value="format">{{ format }}</option>
              </select>
            </div>
          </div>

          <!--          <div v-if="editExport.type === 'beepro'" class="mb-0" :class="{'form-group': isBeelineBranding, 'mt-0': isBeelineBranding, 'mt-2': !isBeelineBranding}">-->
          <!--&lt;!&ndash;            <div v-if="!isBeelineBranding">&ndash;&gt;-->
          <!--&lt;!&ndash;              <h6>{{ $t('radar.settingsBeepro') }}</h6>&ndash;&gt;-->
          <!--&lt;!&ndash;            </div>&ndash;&gt;-->
          <!--&lt;!&ndash;            <div v-else>&ndash;&gt;-->
          <!--&lt;!&ndash;&lt;!&ndash;              <label>{{ $t('radar.settingsEmail') }}</label>&ndash;&gt;&ndash;&gt;-->
          <!--&lt;!&ndash;               <h6>{{ $t('radar.settingsBeepro') }}</h6>&ndash;&gt;-->
          <!--&lt;!&ndash;            </div>&ndash;&gt;-->
          <!--&lt;!&ndash;            <div class="form-group mb-q" v-if="editExport.creds">&ndash;&gt;-->
          <!--&lt;!&ndash;              <label for="subject">{{ $t('radar.subject') }}</label>&ndash;&gt;-->
          <!--&lt;!&ndash;              <input&ndash;&gt;-->
          <!--&lt;!&ndash;                type="text"&ndash;&gt;-->
          <!--&lt;!&ndash;                id="subject"&ndash;&gt;-->
          <!--&lt;!&ndash;                :data-vv-as="$t('radar.subject')"&ndash;&gt;-->
          <!--&lt;!&ndash;                :placeholder="$t('radar.subjectPlaceholder')"&ndash;&gt;-->
          <!--&lt;!&ndash;                name="subject"&ndash;&gt;-->
          <!--&lt;!&ndash;                :disabled="isDisable"&ndash;&gt;-->
          <!--&lt;!&ndash;                class="form-control"&ndash;&gt;-->
          <!--&lt;!&ndash;                v-validate="'required|max:50'"&ndash;&gt;-->
          <!--&lt;!&ndash;                :class="{ input: true, 'is-danger': errors.has('subject') }"&ndash;&gt;-->
          <!--&lt;!&ndash;                v-model="editExport.creds.subject"&ndash;&gt;-->
          <!--&lt;!&ndash;              />&ndash;&gt;-->
          <!--&lt;!&ndash;              <span v-show="errors.has('subject')" class="help is-danger">{{ errors.first('subject') }}</span>&ndash;&gt;-->
          <!--&lt;!&ndash;            </div>&ndash;&gt;-->

          <!--&lt;!&ndash;            <div v-if="isNmsTest && editExport.type==='beepro'" class="form-group">&ndash;&gt;-->
          <!--&lt;!&ndash;              <label for="format">{{ $t('radar.format') }}</label>&ndash;&gt;-->
          <!--&lt;!&ndash;              <select&ndash;&gt;-->
          <!--&lt;!&ndash;                v-model="editExport.format"&ndash;&gt;-->
          <!--&lt;!&ndash;                name="format"&ndash;&gt;-->
          <!--&lt;!&ndash;                id="format"&ndash;&gt;-->
          <!--&lt;!&ndash;                :class="{ 'select-disabled': isDisable }"&ndash;&gt;-->
          <!--&lt;!&ndash;                class="form-control"&ndash;&gt;-->
          <!--&lt;!&ndash;                :disabled="isDisable"&ndash;&gt;-->
          <!--&lt;!&ndash;              >&ndash;&gt;-->
          <!--&lt;!&ndash;                <option v-for="format in this.exportFormat" :key="format" :value="format">{{ format }}</option>&ndash;&gt;-->
          <!--&lt;!&ndash;              </select>&ndash;&gt;-->
          <!--&lt;!&ndash;            </div>&ndash;&gt;-->
          <!--          </div>-->

          <div class="form-group mt-1" v-if="!isBeelineBranding && editExport.type !== 'beepro'">
            <Switch-component
              v-model="editExport.hash"
              :disabled="isDisable || editExport.type !== 'email'"
              :label="$t('radar.hash')"
              id="radar-hash"
            />
            &nbsp;
            <info :content="$t('radar.hashInfo')" />
          </div>

          <div class="form-group mt-1" v-if="isBeelineBranding && editExport.type !== 'beepro'">
            <Switch-component
              v-model="editExport.hash"
              :disabled="isDisable || editExport.type !== 'email'"
              :label="$t('radar.hash')"
              id="radar-hash"
            />
            &nbsp;
            <info :content="$t('radar.hashInfo')" />
          </div>

          <div class="form-group mt-1 mb-q" v-if="!isBeelineBranding && editExport.type !== 'beepro'">
            <Switch-component
              v-model="editExport.auto"
              :disabled="isDisable"
              :label="$t('radar.auto')"
              id="radar-auto"
            />
          </div>
          <div class="form-group mt-1 mb-q" v-if="isBeelineBranding && editExport.type !== 'beepro'">
            <Switch-component
              v-model="editExport.auto"
              :disabled="isDisable"
              :label="$t('radar.auto')"
              id="radar-auto"
            />
          </div>

          <div v-if="editExport.auto" class>
            <div class="form-group" v-if="editExport.auto && editExport.type !== 'yandex'">
              <label for="auto_period">{{ $t('radar.autoPeriod') }}</label>
              <input
                type="text"
                id="auto_period"
                :data-vv-as="$t('radar.autoPeriod')"
                :placeholder="$t('radar.autoPlaceholder')"
                name="auto_period"
                :disabled="isDisable"
                v-validate="'required|numeric'"
                class="form-control"
                :class="{ input: true, 'is-danger': errors.has('auto_period') }"
                v-model.number="editExport.auto_period"
              />
              <span v-show="errors.has('auto_period')" class="help is-danger">{{ errors.first('auto_period') }}</span>
            </div>
          </div>

          <div v-if="editExport.creds && editExport.type !== 'beepro'" class="mt-2">
            <div class="form-group mb-h mt-2">
              <label for="email">{{ $t('radar.share') }}</label>
              <div class="d-flex w-100">
                <input
                  type="text"
                  :disabled="isDisable"
                  name="email"
                  class="form-control input-field-near-button-in-wifi-target"
                  placeholder="user@domain.com"
                  id="email"
                  @input="enableSaveChanges = true"
                  v-validate="'emailForReports'"
                  @keyup.13="addEmail"
                  data-vv-validate-on="input"
                  v-model="newShareEmail"
                  :class="{ input: true, 'is-danger': errors.has('email') }"
                />
                <span class>
                  <WButton success
                    @click="addEmail"
                    :disabled="newShareEmail == '' || errors.has('email')"
                  >
                    {{ $t('general.add') }}
                  </WButton>
                </span>
              </div>
            </div>

            <div class="ml-h mb-q" v-if="editExport.creds.share.length">
              <small>
                <strong>{{ $t('radar.items') }}: {{ editExport.creds.share.length }}</strong>
              </small>
            </div>
            <ul class="items_list p-0 ml-h mb-2">
              <li v-for="(email, index) in editExport.creds.share" :data-index="index" :key="email">
                <small :class="{ 'item-for-delete': !isDisable }" @click="removeEmail" class="text-uppercase">
                  {{ email }}
                  <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
                </small>
              </li>
            </ul>
          </div>

          <!--          <div v-if="editExport.creds && editExport.type === 'beepro'" class="mt-0 mb-0">-->
          <!--            <div class="form-group mb-0 mt-0">-->
          <!--              <label class="mb-0" for="businessId">{{ $t('radar.businessIdsCaption') }}</label>-->
          <!--&lt;!&ndash;              <div class="d-flex w-100">&ndash;&gt;-->
          <!--&lt;!&ndash;                <input&ndash;&gt;-->
          <!--&lt;!&ndash;                  type="number"&ndash;&gt;-->
          <!--&lt;!&ndash;                  :disabled="isDisable"&ndash;&gt;-->
          <!--&lt;!&ndash;                  name="businessIds"&ndash;&gt;-->
          <!--&lt;!&ndash;                  class="form-control"&ndash;&gt;-->
          <!--&lt;!&ndash;                  placeholder="Business Id"&ndash;&gt;-->
          <!--&lt;!&ndash;                  id="businessId"&ndash;&gt;-->
          <!--&lt;!&ndash;                  @input="enableSaveChanges = true"&ndash;&gt;-->
          <!--&lt;!&ndash;                  @keyup.13="addBusinessId"&ndash;&gt;-->
          <!--&lt;!&ndash;                  data-vv-validate-on="input"&ndash;&gt;-->
          <!--&lt;!&ndash;                  v-model="newShareBusinessId"&ndash;&gt;-->
          <!--&lt;!&ndash;                  :class="{ input: true, 'is-danger': errors.has('email') }"&ndash;&gt;-->
          <!--&lt;!&ndash;                />&ndash;&gt;-->
          <!--&lt;!&ndash;                <span class>&ndash;&gt;-->
          <!--&lt;!&ndash;                  <button&ndash;&gt;-->
          <!--&lt;!&ndash;                    class="btn btn-success mb-0"&ndash;&gt;-->
          <!--&lt;!&ndash;                    type="button"&ndash;&gt;-->
          <!--&lt;!&ndash;                    @click="addBusinessId"&ndash;&gt;-->
          <!--&lt;!&ndash;                    :disabled="newShareBusinessId == '' || errors.has('email')"&ndash;&gt;-->
          <!--&lt;!&ndash;                  >&ndash;&gt;-->
          <!--&lt;!&ndash;                    {{ $t('general.add') }}&ndash;&gt;-->
          <!--&lt;!&ndash;                  </button>&ndash;&gt;-->
          <!--&lt;!&ndash;                </span>&ndash;&gt;-->
          <!--&lt;!&ndash;              </div>&ndash;&gt;-->
          <!--            </div>-->
          <!--            <div class="ml mt-h">-->
          <!--              <small>-->
          <!--                <strong>{{ $t('radar.user') }}:</strong>-->
          <!--              </small>-->
          <!--            </div>-->
          <!--            <multiselect-->
          <!--              id="businessId"-->
          <!--              @input="changeUserWithBusinessIdInSelector"-->
          <!--              :closeOnSelect="true"-->
          <!--              :hideSelected="true"-->
          <!--              :disabled="isDisable"-->
          <!--              :multiple="false"-->
          <!--              v-model="newShareBusinessIdSelectedObject"-->
          <!--              :options="usersWithBeeProBisId || []"-->
          <!--              label="username"-->
          <!--              trackBy="bee_pro_bis_id"-->
          <!--              :selectedLabel="$t('general.multiSelectSelected')"-->
          <!--              selectLabel="+"-->
          <!--              deselectLabel="-"-->
          <!--              :placeholder="$t('radar.usersWithBusinessIdsPlaceholder')"-->
          <!--            ></multiselect>-->
          <!--            <div class="ml mb-q" v-if="isShowingBisIdError">-->
          <!--              <small>-->
          <!--                <strong style="color:red">{{$t('radar.businessIdErrorShow')}}</strong>-->
          <!--              </small>-->
          <!--            </div>-->

          <!--            <div class="ml mt-h" v-if="editExport.creds.share.length">-->
          <!--              <small>-->
          <!--                <strong>{{ $t('radar.selectedBusinessIdsCaption') }}:</strong>-->
          <!--              </small>-->
          <!--            </div>-->
          <!--            <ul class="items_list p-0 mb-2">-->
          <!--              <li v-for="(businessId, index) in editExport.creds.share" :data-index="index" :key="businessId">-->
          <!--                <small :class="{ 'item-for-delete': !isDisable }" @click="removeBusinessId" class="text-uppercase">-->
          <!--                  {{ businessId }}-->
          <!--                  <i v-if="!isDisable" class="fa fa-remove text-danger"></i>-->
          <!--                </small>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </div>-->

          <div v-if="editExport.type === 'beepro'" class="mt-0 mb-0">
            <div class="form-group mb-0 mt-0">
              <label class="mb-0" for="ctnField">{{ $t('radar.ctnCaption') }}</label>
              <!--              <div class="d-flex w-100">-->
              <!--                <input-->
              <!--                  type="number"-->
              <!--                  :disabled="isDisable"-->
              <!--                  name="businessIds"-->
              <!--                  class="form-control"-->
              <!--                  placeholder="Business Id"-->
              <!--                  id="businessId"-->
              <!--                  @input="enableSaveChanges = true"-->
              <!--                  @keyup.13="addBusinessId"-->
              <!--                  data-vv-validate-on="input"-->
              <!--                  v-model="newShareBusinessId"-->
              <!--                  :class="{ input: true, 'is-danger': errors.has('email') }"-->
              <!--                />-->
              <!--                <span class>-->
              <!--                  <button-->
              <!--                    class="btn btn-success mb-0"-->
              <!--                    type="button"-->
              <!--                    @click="addBusinessId"-->
              <!--                    :disabled="newShareBusinessId == '' || errors.has('email')"-->
              <!--                  >-->
              <!--                    {{ $t('general.add') }}-->
              <!--                  </button>-->
              <!--                </span>-->
              <!--              </div>-->
            </div>
            <div class="ml mt-h">
              <small>
                <strong>{{ $t('radar.user') }}:</strong>
              </small>
            </div>
            <multiselect
              id="ctnField"
              @input="changeUserWithCtnInSelector"
              :closeOnSelect="true"
              :hideSelected="true"
              :disabled="isDisable"
              :multiple="false"
              v-model="newShareBusinessIdSelectedObject"
              :options="usersWithBeeProCTN || []"
              label="username"
              trackBy="bee_pro_ctn"
              :selectedLabel="$t('general.multiSelectSelected')"
              selectLabel="+"
              deselectLabel="-"
              :placeholder="$t('radar.usersWithBusinessIdsPlaceholder')"
            ></multiselect>
            <div class="ml mb-q" v-if="isShowingCTNError">
              <small>
                <strong style="color: red;">{{ $t('radar.ctnErrorShow') }}</strong>
              </small>
            </div>

            <div class="ml mt-h">
              <small>
                <strong>{{ $t('radar.selectedCTNCaption') }}:</strong>
              </small>
            </div>
            <ul class="items_list p-0 mb-2" v-if="this.editExport.ctn != ''">
              <small
                v-if="this.editExport.ctn != ''"
                :class="{ 'item-for-delete': !isDisable }"
                @click="removeCTN"
                class="text-uppercase"
              >
                {{ this.editExport.ctn }}
                <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
              </small>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="editExport"></div>

      <!-- Modal footer -->

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <div>
          <WButton success outline
            customClass="mr-1"
            v-if="!isNewExport && !isDisable"
            @click="updateExport(editExport)"
            :disabled="
              errors.any() ||
              muteEdit ||
              isShowingDateRangeLimitExceededError ||
              isMaximumCpesWithEnabledWiFiTargetSelected
            "
          >
            <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
            <span v-if="muteEdit" class="loader loader--mini"></span>
          </WButton>
          <WButton primary outline
            v-if="!isNewExport && !isDisable"
            @click="copyExport(editExport)"
            :disabled="
              errors.any() ||
              muteEdit ||
              isShowingDateRangeLimitExceededError ||
              isMaximumCpesWithEnabledWiFiTargetSelected
            "
          >
            <span :class="{ invisible: muteEdit }">{{ $t('general.copy') }}</span>
            <span v-if="muteEdit" class="loader loader--mini"></span>
          </WButton>
          <WButton success outline
            v-else-if="!isDisable"
            @click="crateExport(editExport)"
            :disabled="
              muteEdit ||
              errors.any() ||
              isShowingDateRangeLimitExceededError ||
              isMaximumCpesWithEnabledWiFiTargetSelected
            "
          >
            <span :class="{ invisible: muteEdit }">{{ $t('general.create') }}</span>
            <span v-if="muteEdit" class="loader loader--mini"></span>
          </WButton>
        </div>

        <div>
          <WButton danger outline
            customClass="mr-2"
            v-if="!isNewExport && !isDisable"
            @click="deletingExportModal = true"
          >
            {{ $t('general.delete') }}
          </WButton>
          <WButton secondary outline
            @click="cancelEditMode"
          >
            {{ $t('general.close') }}
          </WButton>
        </div>
      </div>
    </modal>

    <modal title="Delete Export" v-model="deletingExportModal" class="modal-danger" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('radar.deleteModalTitle') }}</h4>
      {{ $t('radar.deleteModalText') }}
      <b>{{ editExport.name }}</b>

      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="deletingExportModal = false"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteExport(editExport)"
        >
          {{ $t('general.yesDelete') }}
        </WButton>
      </div>
    </modal>

    <!--    <Modal-->
    <!--      title="Show Maximum Cpes With Enabled WiFiTarget Selected Error Modal"-->
    <!--      class="modal-info"-->
    <!--      v-model="showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal"-->
    <!--      effect="fade/zoom"-->
    <!--    >-->
    <!--      <h4 slot="modal-title" class="modal-title">{{$t('radar.maximumCpesWithEnabledWiFiTargetSelectedErrorMoreModalHeader')}}</h4>-->
    <!--      <div>-->
    <!--        <p>{{$t('radar.maximumCpesWithEnabledWiFiTargetSelectedErrorMoreModalHeader')}}: </p>-->
    <!--      </div>-->
    <!--      <div v-if="locale === 'ru'">-->
    <!--        <ul>-->
    <!--          <li>-->
    <!--            <b>В диапазоне до одного месяца</b>: любое количество ТД (с включенным Wi-FI Таргет)-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <b>В диапазоне от одного месяца до двух месяцев</b>: не более 50 ТД (с включенным Wi-FI Таргет)-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <b>В диапазоне от двух до трех месяцев</b>: не более 30 ТД (с включенным Wi-FI Таргет)-->
    <!--          </li>-->
    <!--        </ul>-->
    <!--      </div>-->
    <!--      <div v-else>-->
    <!--        <ul>-->
    <!--          <li>-->
    <!--            <b>In the range of up to one month</b>: Any number of CPEs (with Wi-FI Target enabled)-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <b>Ranging from one month to two months</b>: No more than 50 CPEs (with Wi-FI Target enabled)-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <b>Ranging from two month to three months</b>: No more than 30 CPEs (with Wi-FI Target enabled)-->
    <!--          </li>-->
    <!--        </ul>-->

    <!--      </div>-->
    <!--      <div slot="modal-footer" class="modal-footer modal-btn-right">-->
    <!--        <button type="button" class="btn btn-secondary"-->
    <!--                @click="showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal = false">-->
    <!--          {{ $t('general.close') }}-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </Modal>-->
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment';
import { Multiselect } from 'vue-multiselect';
import Alert from '@/components/Universal/alert/alert.vue';
import cpeService from '../../services/cpeService';
import DatePicker from '../../components/date-picker.vue';
import helpers from '../../helpers';
import Modal from '../../components/Modal.vue';
import radarService from '../../services/radarService';

import locationService from '../../services/locationService';
import Filters from '../../filters';
import commonService from '../../services/commonService';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import Info from '../../components/Universal/info-icon.vue';
import usersService from '../../services/usersService';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

const { mapGetters: localeMapGetters } = createNamespacedHelpers('locale');
const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');

export default {
  name: 'Radar',
  components: {
    Modal,
    Multiselect,
    DatePicker,
    SwitchComponent,
    Info,
    Alert,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      loadingAllCpesForLocation: false,
      cpeListLengthForSelectedInModalLocation: 0,
      loadingDataForCpeListLength: false,
      loadingDataForCpeListForMultiedit: false,
      cpeListForCpeSearchForMultiedit: [],
      showSortByConnectionnOrDisconnectionTimeDateRangeFilter: false,
      showLocationFilter: true,
      locationInFiltersData: [],
      showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal: false,
      isShowingDateRangeLimitExceededError: false,
      dateRangeLimitExceededErrorRangeLimit: 0,
      showingWifiTargetDisabledOnAllSelectedCPESMsg: false,
      charactersForCpeListSeparateNameFromDescription: ':::', // используем при создании списка CPE с описаниями  для мультиселекта
      showDescriptionInCpesFilter: false,
      isShowingBisIdError: false,
      isShowingCTNError: false,
      newShareBusinessIdSelectedObject: {},
      usersWithBeeProBisId: [],
      usersWithBeeProCTN: [],
      isShowDatepickerInModal: true,
      businessIdsList: [],
      editExportCompany: 'All companys',
      searchSpinner: false,
      delayTimer: null,
      isDatePickerVisible: true,
      isExportModalOpen: false,
      isContinuously: false,
      isNewExport: false,
      editExport: {},
      deletingExportModal: false,
      exportType: ['yandex', 'mytarget', 'email'],
      exportTypeForBeeline: ['email', 'beepro'],
      exportFilter: ['new', 'return', 'all'],
      exportFormat: ['csv', 'json', 'txt'],
      exportStatus: ['created', 'processed', 'running', 'finished'],
      newShareEmail: '',
      newShareBusinessId: '',
      enableSaveChanges: '',
      exportCpes: [],
      drPickerDateForExport: {
        startDate: moment().subtract(24, 'hours').valueOf(),
        endDate: moment().valueOf()
      },
      filters: {
        company: 'All companys',
        sortByFilter: 'Name',
        sortOrder: 'asc',
        query: '',
        typeFilter: '',
        location: 'All locations',
        with_childs: false,
        status: '',
        dataRangeFilterType: 'Created',
        isDateRangeFilterEnabled: false,
        drPickerDate: {
          startDate: moment().startOf('day').valueOf(),
          endDate: moment().endOf('day').valueOf()
        }
      },
      filtersForCpeList: {
        isFiltersForCpeListEnabled: false,
        isFiltersForCpeListBlockShowing: false,
        location: 'All locations',
        radarStatus: 'Any'
      }
    };
  },
  computed: {
    ...localeMapGetters(['isRuLocale']),
    ...brandingMapGetters(['isBeelineBranding']),
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    showSpinnerOverCpeFiltersBlockInModal() {
      return this.loadingDataForCpeListLength || this.loadingAllCpesForLocation;
    },
    locationIdForCpeSearchRequests() {
      try {
        if (this.filtersForCpeList.location && this.filtersForCpeList.location !== 'All locations') {
          return this.filtersForCpeList.location;
        }
        return undefined;
      } catch (e) {
        console.log(e);
        return undefined

      }
    },
    wifiTargetFilterForCpeSearchRequests() {
      try {
        if (this.filtersForCpeList.radarStatus && this.filtersForCpeList.radarStatus !== 'Any') {
          return this.filtersForCpeList.radarStatus;
        }
        return undefined;
      } catch (e) {
        console.log(e);
        return undefined

      }
    },

    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    selectedCpesWithEnabledWifiTargetCounter() {
      // подсчитываем сколько выбраных ТД с включеным wifi таргет
      try {
        let counter = 0;
        for (const cpe of this.exportCpes) {
          if (cpe.config.lbs_config.enabled === true) {
            counter += 1;
          }
        }
        return counter;
      } catch (e) {
        console.log(e);
        return 0;
      }
    },
    isFiltersActive() {
      // console.log('this.filters.company', this.filters.company)
      // console.log('this.filters.sortByFilter', this.filters.sortByFilter)
      // console.log('this.filters.sortOrder', this.filters.sortOrder)
      // console.log('this.filters.query ', this.filters.query )
      // console.log('this.filters.typeFilter', this.filters.typeFilter)
      // console.log('this.filters.location', this.filters.location)
      // console.log('this.filters.status', this.filters.status)
      // console.log('this.filters.dataRangeFilterType', this.filters.dataRangeFilterType)
      // console.log('this.drPickerDate.startDate ', this.filters.drPickerDate.startDate )
      //   console.log('this.drPickerDate.endDate ', this.filters.drPickerDate.endDate)
      return this.filters.company !== 'All companys' ||
        this.filters.sortByFilter !== 'Name' ||
        this.filters.sortOrder !== 'asc' ||
        this.filters.query !== '' ||
        this.filters.typeFilter !== '' ||
        this.filters.location !== 'All locations' ||
        this.filters.status !== '' ||
        this.filters.dataRangeFilterType !== 'Created' ||
        this.filters.isDateRangeFilterEnabled !== false ||
        this.filters.drPickerDate.startDate !== moment().startOf('day').valueOf() ||
        this.filters.drPickerDate.endDate  !== moment().endOf('day').valueOf()

    },
    isMaximumCpesWithEnabledWiFiTargetSelected() {
      // считаем сколько выбрано точек с включеным wifi таргет в зависимости от выбраного
      // врменного промежутка
      // по следующему алгагитму:
      // если выбираем период меньше месяца, то точек (включенных wifi target) можем добавить любое количество,
      // если от месяца до двух - то не более 50,
      // от 2 до трех – не более 30

      try {
        const ONE_MONTH = 31 * 24 * 60 * 60 * 1000; // в милисекундах
        const TWO_MONTH = ONE_MONTH * 2 + 24 * 60 * 60 * 1000;
        const THREE_MONTH = ONE_MONTH * 3 + 24 * 60 * 60 * 1000;

        // смотрим какие там нитервалы выбраны
        const { startDate } = this.drPickerDateForExport;
        const { endDate } = this.drPickerDateForExport;
        const datesDelta = endDate - startDate;

        // console.log('ONE_MONTH', ONE_MONTH);
        // console.log('TWO_MONTH', TWO_MONTH);
        // console.log('THREE_MONTH', THREE_MONTH);
        // console.log('endDate', endDate);
        // console.log('startDate', startDate);
        // console.log('drPickerDateForExport', this.drPickerDateForExport)
        // console.log('datesDelta', datesDelta);

        if (datesDelta < ONE_MONTH) {
          return false; // нет лимита, т.к выбраный интервал меньше месяца
        } if (datesDelta >= ONE_MONTH && datesDelta < TWO_MONTH) {
          // считаем чтобы было не больше 50 выбраных точек с включеным wifi таргет
          if (this.selectedCpesWithEnabledWifiTargetCounter > 50) {
            return true;
          }
          return false;
        } if (datesDelta >= TWO_MONTH && datesDelta < THREE_MONTH) {
          // считаем чтобы было не больше 30 выбраных точек с включеным wifi таргет
          if (this.selectedCpesWithEnabledWifiTargetCounter > 30) {
            return true;
          }
          return false;

          // } else if (datesDelta >= THREE_MONTH) {
          //   if (this.selectedCpesWithEnabledWifiTargetCounter > 0) {
          //     return true;
          //   } else {
          //     return false;
          //   }
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    usersWithBeeProBisIdLength() {
      return this.usersWithBeeProBisId.length;
    },
    usersWithBeeProCTNLength() {
      return this.usersWithBeeProCTN.length;
    },
    isUsersWithBeeProBisIdLoading() {
      return this.$store.loadingUsersWithBeeProBisId;
    },
    isUsersWithBeeProCTNLoading() {
      return this.$store.loadingUsersWithBeeProCTN;
    },
    isNmsTest() {
      // используется чтобы отображать функционал для работы с выгрузками БиПро на nmstest (помимо отображения в билайновской сборке)
      // задача WNE-2412
      return this.$store.getters.isNmsTest;
    },
    beeProBusinessIDForCurrentUser() {
      return this.$store.getters.getBeeProBusinessIDForCurrentUser;
    },
    companys() {
      return this.$store.getters.getCompanyList;
    },
    companyList() {
      return this.$store.getters.companyListSorted;
    },
    isGetRadarExportsReqExecuted() {
      return this.$store.state.isGetRadarExportsReqExecuted;
    },
    radarExportList() {
      return this.$store.state.radarExportList;
    },
    // filteredList() {
    //   // использовалось для фильтрации и поиска по табличке локально, до начала использования
    //   // для этих целей запросов к API

    //   let copy = JSON.parse(JSON.stringify(this.radarExportList));
    //   const startDateTime = moment(this.filters.drPickerDate.startDate).unix();
    //   const endDateTime = moment(this.filters.drPickerDate.endDate).unix();
    //
    //   copy = Filters.locationFilter(copy, this.filters.location);
    //   copy = Filters.radarStatusFilter(copy, this.filters.status);
    //   copy = Filters.radarTypeFilter(copy, this.filters.typeFilter);
    //   copy = Filters.radarSearchQuery(copy, this.filters.query);
    //   if (this.filters.isDateRangeFilterEnabled) {
    //     if (this.filters.dataRangeFilterType === 'Created') {
    //       copy = Filters.radarCreatedDateFilter(copy, startDateTime, endDateTime);
    //     } else if (this.filters.dataRangeFilterType === 'Updated') {
    //       copy = Filters.radarUpdatedDateFilter(copy, startDateTime, endDateTime);
    //     }
    //   }
    //   return copy;
    // },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    locationsListForCpeFilterInModal() {
      if (this.isSelectedDefaultLocationForRequests) {
        return this.$store.getters.locationsListSortedOnlySelectedInHeaderLocationAndChilsdsIfEnabled
      } else{
        return this.$store.getters.locationsListSorted;
      }
    },
    muteEdit() {
      return this.$store.state.muteExportEdit;
    },
    cpesList() {
      return this.$store.state.cpesList;
    },
    isCpesListFiltering() {
      return this.$store.state.filteringCpeListInRadarExportModalWindow;
    },
    // filterdCpesList() {
    //   this.$store.commit('filteringCpeListInRadarExportModalWindowStart')
    //   try {
    //     let filtredCpes = JSON.parse(JSON.stringify(this.cpesList));
    //     if (this.filtersForCpeList.isFiltersForCpeListEnabled) {
    //       if (this.filtersForCpeList.location !== 'All locations') {
    //         filtredCpes = filtredCpes.filter((cpe) => {
    //           return cpe.base_location === this.filtersForCpeList.location;
    //         });
    //       }
    //       if (this.filtersForCpeList.radarStatus !== 'Any') {
    //         if (this.filtersForCpeList.radarStatus === 'Enabled') {
    //           filtredCpes = filtredCpes.filter((cpe) => {
    //             return cpe.config.lbs_config.enabled === true;
    //           });
    //         }
    //         if (this.filtersForCpeList.radarStatus === 'Disabled') {
    //           filtredCpes = filtredCpes.filter((cpe) => {
    //             return cpe.config.lbs_config.enabled === false;
    //           });
    //         }
    //       }
    //     }
    //     this.$store.commit('filteringCpeListInRadarExportModalWindowEnd')
    //     return filtredCpes;
    //   } catch (e) {
    //     console.log(e);
    //     this.$store.commit('filteringCpeListInRadarExportModalWindowEnd')
    //     return this.cpesList;
    //   }
    // },
    filterdCpesList() {
      this.$store.commit('filteringCpeListInRadarExportModalWindowStart');
      try {
        let filtredCpes = JSON.parse(JSON.stringify(this.cpesList));
        if (this.filtersForCpeList.isFiltersForCpeListEnabled) {
          if (this.filtersForCpeList.location !== 'All locations') {
            filtredCpes = filtredCpes.filter((cpe) => cpe.base_location === this.filtersForCpeList.location);
          }
          if (this.filtersForCpeList.radarStatus !== 'Any') {
            if (this.filtersForCpeList.radarStatus === 'Enabled') {
              filtredCpes = filtredCpes.filter((cpe) => cpe.config.lbs_config.enabled === true);
            }
            if (this.filtersForCpeList.radarStatus === 'Disabled') {
              filtredCpes = filtredCpes.filter((cpe) => cpe.config.lbs_config.enabled === false);
            }
          }
        }
        this.$store.commit('filteringCpeListInRadarExportModalWindowEnd');
        if (this.showDescriptionInCpesFilter === true) {
          for (const cpeIndex in filtredCpes) {
            if (filtredCpes[cpeIndex].description !== '') {
              filtredCpes[
                cpeIndex
              ].name = `${filtredCpes[cpeIndex].name}${this.charactersForCpeListSeparateNameFromDescription} ${filtredCpes[cpeIndex].description}`;
            } else {
              filtredCpes[cpeIndex].name = `${filtredCpes[cpeIndex].name}`;
            }
          }
        }
        return filtredCpes;
      } catch (e) {
        console.log(e);
        this.$store.commit('filteringCpeListInRadarExportModalWindowEnd');
        return this.cpesList;
      }
    },
    isSelectedNoDefaultsValuesInFiltersForCPEListANDFiltringEnabled() {
      // если в выпадающих списках что-то выбрано и фильтрация включена, выводим true, чтобы потом
      // показать плашку о том что филтрация включена в UI
      if (
        this.filtersForCpeList.isFiltersForCpeListEnabled &&
        (this.filtersForCpeList.radarStatus !== 'Any' || this.filtersForCpeList.location !== 'All locations')
      ) {
        return true;
      }
      return false;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    selectedCpesCount() {
      try {
        if (Array.isArray(this.exportCpes)) {
          return this.exportCpes.length;
        }
        return 0;
      } catch (e) {
        return 0;
      }
    },
    isCpesLoading() {
      return this.$store.state.loadingCpes;
    }
  },
  methods: {
    async getAllCpesForLocation(locationId, with_childs) {
      try {
        const params = {}
        if (this.wifiTargetFilterForCpeSearchRequests) {
          if (this.wifiTargetFilterForCpeSearchRequests === 'Enabled') {
            params.q = {'config.lbs_config.enabled': true}
          }
          if (this.wifiTargetFilterForCpeSearchRequests === 'Disabled') {
            params.q = {'config.lbs_config.enabled': false}
          }
        }
        this.loadingAllCpesForLocation = true;
        const response = await cpeService.requestCompactCpesWithSearchForUseInWiFiTarget('', locationId, with_childs, this.cpeListLengthForSelectedInModalLocation, params);
        // console.log(response)
        return response.itemslist;
      } catch (e) {
        console.log(e)
        return []
      } finally {
        this.loadingAllCpesForLocation = false;

      }
    },
    async selectLocationInCpesFilterForModal() {
      const params = {}
      if (this.wifiTargetFilterForCpeSearchRequests){
       if (this.wifiTargetFilterForCpeSearchRequests === 'Enabled') {
         params.q = {'config.lbs_config.enabled': true}
       } if (this.wifiTargetFilterForCpeSearchRequests === 'Disabled'){
         params.q = {'config.lbs_config.enabled': false}
        }
      }
      if (this.locationIdForCpeSearchRequests) {
        this.getCpesLengthForLocation(this.locationIdForCpeSearchRequests, false, params)
      } else {
        this.getCpesLengthForLocation(undefined, true, params)
      }
    },
    async selectWifiTargetFilterInCpesFilterForModal() {
      this.selectLocationInCpesFilterForModal()
    },
    async getCpesLengthForLocation(locationId, with_childs, params) {
      try {
        this.loadingDataForCpeListLength = true;
       //  console.log(params)
        const response = await cpeService.requestCompactCpesWithSearchForUseInWiFiTarget('', locationId, with_childs, undefined, params);
       // console.log(response)
        this.cpeListLengthForSelectedInModalLocation = response.total;
      } catch (e) {
        console.log(e)
        this.cpeListLengthForSelectedInModalLocation = undefined;
      } finally {
        this.loadingDataForCpeListLength = false;

        // console.log(this.wlansListForWlansSearch[id]);
      }
    },
    async asyncSearchCpeWhenOpenSelectorListForMultiedit(id) {
      return this.asyncSearchCpeForMultiedit('', 0);
    },
    async asyncSearchCpeWhenSearchChangeForMultiedit(query) {
      return this.asyncSearchCpeForMultiedit(query);
    },
    async asyncSearchCpeForMultiedit(query, timeout = 1000) {
      const params = {}
      if (this.wifiTargetFilterForCpeSearchRequests){
       if (this.wifiTargetFilterForCpeSearchRequests === 'Enabled') {
         params.q = {'config.lbs_config.enabled': true}
       } if (this.wifiTargetFilterForCpeSearchRequests === 'Disabled'){
         params.q = {'config.lbs_config.enabled': false}
        }
      }
      if (this.timeoutForCpeSearchForMultiedit !== undefined) {
        clearTimeout(this.timeoutForCpeSearchForMultiedit)
      }
      this.loadingDataForCpeListForMultiedit = true;
      this.timeoutForCpeSearchForMultiedit = setTimeout(async () => {


        try {
          let response
          if (this.locationIdForCpeSearchRequests){
            response = await cpeService.requestCompactCpesWithSearchForUseInWiFiTarget(query, this.locationIdForCpeSearchRequests, false, undefined, params);
          }else {
            response = await cpeService.requestCompactCpesWithSearchForUseInWiFiTarget(query, undefined, true, undefined, params);
          }
          // const response = await cpeService.requestCompactCpesWithSearch(query, this.locationIdForCpeSearchRequests);
          this.cpeListForCpeSearchForMultiedit = response.itemslist;
        } catch (e) {
          console.log(e)
          this.cpeListForCpeSearchForMultiedit = [];
        } finally {
          this.loadingDataForCpeListForMultiedit = false;

          // console.log(this.wlansListForWlansSearch[id]);
        }
      }, timeout);
    },

    showMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal() {
      this.showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal = true;
    },
    toggleMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal() {
      if (this.showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal === true) {
        this.showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal = false;
      } else {
        this.showingMaximumCpesWithEnabledWiFiTargetSelectedErrorMoreModal = true;
      }
    },
    dateRangeLimitEnabledAndExceeded(payload) {
      if (payload.dateRangeLimitEnabledAndExceeded === true) {
        this.isShowingDateRangeLimitExceededError = true;
        this.dateRangeLimitExceededErrorRangeLimit = payload.maxDateRangeLimit;
      } else {
        this.isShowingDateRangeLimitExceededError = false;
        this.dateRangeLimitExceededErrorRangeLimit = payload.maxDateRangeLimit;
      }
    },
    showWifiTargetDisabledOnAllSelectedCPESMsg() {
      this.showingWifiTargetDisabledOnAllSelectedCPESMsg = true;
    },
    hideWifiTargetDisabledOnAllSelectedCPESMsg() {
      this.showingWifiTargetDisabledOnAllSelectedCPESMsg = false;
    },
    refreshExports() {
      this.getExportsWithFilterQuery();
    },
    toggleSortByConnectionnOrDisconnectionTimeDateRangeFilter() {
      this.showSortByConnectionnOrDisconnectionTimeDateRangeFilter = !this.showSortByConnectionnOrDisconnectionTimeDateRangeFilter;
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.getExportsWithFilterQuery()


    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters =  {
    //     company: 'All companys',
    //     sortByFilter: 'Name',
    //     sortOrder: 'asc',
    //     query: '',
    //     typeFilter: '',
    //     location: 'All locations',
    //     with_childs: false,
    //     status: '',
    //     dataRangeFilterType: 'Created',
    //     isDateRangeFilterEnabled: false,
    //     drPickerDate: {
    //       startDate: moment().startOf('day').valueOf(),
    //       endDate: moment().endOf('day').valueOf()
    //     }
    //   }
    //   this.getExportsWithFilterQuery()
    //
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters =  {
        company: 'All companys',
        sortByFilter: 'Name',
        sortOrder: 'asc',
        query: '',
        typeFilter: '',
        // location: 'All locations',
        // with_childs: false,
        status: '',
        dataRangeFilterType: 'Created',
        isDateRangeFilterEnabled: false,
        drPickerDate: {
          startDate: moment().startOf('day').valueOf(),
          endDate: moment().endOf('day').valueOf()
        }
      }
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      this.getExportsWithFilterQuery()

    },

    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    changeCPEsInMultiselectInModal() {
      this.hideWifiTargetDisabledOnAllSelectedCPESMsg();
      try {
        if (Array.isArray(this.exportCpes)) {
          for (const cpe of this.exportCpes) {
            if (cpe.name.includes(this.charactersForCpeListSeparateNameFromDescription)) {
              cpe.name = cpe.name.split(this.charactersForCpeListSeparateNameFromDescription)[0];
            }
          }
          let areAllSelectedCPESWithDisabledRadar = true;
          for (const cpe of this.exportCpes) {
            if (cpe.config.lbs_config.enabled === true) {
              areAllSelectedCPESWithDisabledRadar = false;
              break;
            }
          }
          if (areAllSelectedCPESWithDisabledRadar && this.exportCpes.length > 0) {
            // console.log('все CPE с выключеным радаром');
            this.showWifiTargetDisabledOnAllSelectedCPESMsg();
          }
        }
      } catch (e) {}
    },
    cutOffLongTextForCPEDescription(text) {
      try {
        if (text.length > 60) {
          return `${text.slice(0, 60)}...`;
        }
        return text;
      } catch (e) {
        return text;
      }
    },
    toggleShowingDescriptionInCpesFilter() {
      this.showDescriptionInCpesFilter = !this.showDescriptionInCpesFilter;
    },
    goToBeeProCabinet() {
      window.open('https://marketing.beeline.ru/my-audiences', '_blank');
    },
    checkForBeeProInFinishedStatus(rExport) {
      try {
        if (rExport.type === 'beepro' && rExport.status === 'finished') {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    fillNewShareBusinessIdSelectedObjectByBisId(bisId) {
      const user_obj = this.findUserObjectByBusinessId(bisId);
      if (user_obj !== undefined) {
        this.newShareBusinessIdSelectedObject = user_obj;
      } else {
        this.newShareBusinessIdSelectedObject = {};
      }
    },

    fillNewShareBusinessIdSelectedObjectByCTN(ctn) {
      const user_obj = this.findUserObjectByCTN(ctn);
      // console.log(user_obj)
      if (user_obj !== undefined) {
        this.newShareBusinessIdSelectedObject = user_obj;
      } else {
        this.newShareBusinessIdSelectedObject = {};
      }
    },

    findUserObjectByBusinessId(bis_id) {
      for (const user of this.usersWithBeeProBisId) {
        if (user.bee_pro_bis_id == bis_id) {
          return JSON.parse(JSON.stringify(user));
        }
      }
      return undefined;
    },

    findUserObjectByCTN(ctn) {
      for (const user of this.usersWithBeeProCTN) {
        if (user.bee_pro_ctn == ctn) {
          return JSON.parse(JSON.stringify(user));
        }
      }
      return undefined;
    },

    changeUserWithBusinessIdInSelector() {
      // console.log('selected')
      this.isShowingBisIdError = false;
      this.newShareBusinessId = this.newShareBusinessIdSelectedObject.bee_pro_bis_id;
      this.$nextTick(() => {
        this.addBusinessId();
      });
    },
    changeUserWithCtnInSelector() {
      // this.isShowingBisIdError = false;
      this.isShowingCTNError = false;
      this.editExport.ctn = this.newShareBusinessIdSelectedObject.bee_pro_ctn;
      // return bis id filling
      this.changeUserWithBusinessIdInSelector();
    },
    changingName() {
      if (this.editExport.type === 'beepro') {
        this.editExport.creds.subject = this.editExport.name;
      }
    },
    changeCompanyInModal() {
      if (this.editExportCompany !== 'All companys') {
        // console.log('change')
        let firstLocationIdForCompany = '';
        if (this.editExportCompany.hasOwnProperty('locations') && this.editExportCompany.locations.length > 0) {
          firstLocationIdForCompany = this.editExportCompany.locations[0];
          // console.log(firstLocationIdForCompany);
        }
        this.editExport.base_location = firstLocationIdForCompany;
      }
    },
    changeCompany() {
      let firstLocationIdForCompany = '';
      if (this.filters.company.hasOwnProperty('locations') && this.filters.company.locations.length > 0) {
        firstLocationIdForCompany = this.filters.company.locations[0];
        // console.log(firstLocationIdForCompany);
      } else {
        firstLocationIdForCompany = 'All locations';
      }
      // this.updatedPage.base_location = firstLocationIdForCompany;
      this.filters.location = firstLocationIdForCompany;
      this.getExportsWithFilterQuery();
    },
    locationsObjbyIds(ids) {
      return commonService.locationsObjbyIds(ids);
    },
    copyExport(rExport) {
      this.errors.clear();
      this.isShowDatepickerInModal = false;
      this.$nextTick(() => {
        this.isShowDatepickerInModal = true;
      });
      this.isExportModalOpen = false;
      this.clearFiltersForCpeList();
      this.isExportModalOpen = true;
      this.isNewExport = true;
      this.exportCpes = [];
      // if (isNew) {
      //   const location = this.$store.state.userData.base_location;
      //   this.isNewExport = true;
      //   if (this.isBeelineBranding) {
      //     // console.log('beeline');
      //     this.editExport = {
      //       type: 'email',
      //       hash: true,
      //       filter: 'all',
      //       format: 'txt',
      //       base_location: location !== undefined ? location : '',
      //       creds: {
      //         share: []
      //       },
      //       period: {
      //         start: moment().subtract(24, 'hours').valueOf(),
      //         stop: moment().valueOf()
      //       }
      //     };
      //   } else {
      //     // console.log('not beeline');
      //     this.editExport = {
      //       type: 'email',
      //       filter: 'all',
      //       format: 'txt',
      //       base_location: location !== undefined ? location : '',
      //       creds: {
      //         share: []
      //       },
      //       period: {
      //         start: moment().subtract(24, 'hours').valueOf(),
      //         stop: moment().valueOf()
      //       }
      //     };
      //   }
      // } else {

      this.editExport = JSON.parse(JSON.stringify(rExport));
      this.editExport.name = `${this.editExport.name}-copy`;
      // console.log(this.editExport.name)
      if (this.editExport.hasOwnProperty('last_at')) {
        delete this.editExport.last_at;
      }
      if (this.editExport.hasOwnProperty('create_at')) {
        delete this.editExport.create_at;
      }

      // this.drPickerDateForExport.startDate = moment().subtract(24, 'hours').valueOf();
      // this.drPickerDateForExport.endDate = moment().valueOf();
      this.drPickerDateForExport.startDate = this.editExport.period.start * 1000;
      this.drPickerDateForExport.endDate = this.editExport.period.stop * 1000;

      if (this.editExport.period.stop === 0) {
        this.isContinuously = true;
      }

      const { cpesList } = this.$store.state;
      if (cpesList && this.editExport && this.editExport.cpes) {
        const cpesItems = helpers.transformArrayToObject(cpesList);
        this.editExport.cpes.map((cpe) => {
          const fullCpe = cpesItems[cpe];
          fullCpe.id = cpe;
          this.exportCpes.push(fullCpe);
        });
      }
      // this.$validator.validateAll().then((result) => {
      //   if (result) {
      //     radarService.crateExport(this, this.editExport);
      //   }
      // });
      radarService.crateExport(this, this.editExport);
    },
    createCopy(rExport) {
      // console.log(rExport)
      this.openExportModalAndCreateCopy(rExport);
    },
    toggleSortOrder() {
      if (this.filters.sortOrder === 'asc') {
        this.filters.sortOrder = 'desc';
      } else {
        this.filters.sortOrder = 'asc';
      }
      this.getExportsWithFilterQuery();
    },
    clearSerchInput() {
      this.filters.query = '';
      this.getExportsWithFilterQuery();
    },
    searchInput() {
      this.searchSpinner = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.getExportsWithFilterQuery();
        this.$nextTick(() => {
          this.searchSpinner = false;
        });
      }, 1000);
    },
    // resetFilters() {
    //   this.filters = {
    //     sortByFilter: 'Name',
    //     sortOrder: 'asc',
    //     query: '',
    //     typeFilter: '',
    //     location: 'All locations',
    //     company: 'All companys',
    //     status: '',
    //     dataRangeFilterType: 'Created',
    //     isDateRangeFilterEnabled: false,
    //     drPickerDate: {
    //       startDate: moment().startOf('day').valueOf(),
    //       endDate: moment().endOf('day').valueOf()
    //     }
    //   };
    // },
    resetFilters() {
      this.filters = {
        sortByFilter: 'Name',
        sortOrder: 'asc',
        query: '',
        typeFilter: '',
        // location: 'All locations',
        company: 'All companys',

        status: '',
        dataRangeFilterType: 'Created',
        isDateRangeFilterEnabled: false,
        drPickerDate: {
          startDate: moment().startOf('day').valueOf(),
          endDate: moment().endOf('day').valueOf()
        }
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }
      this.toggleShowLocationFilter();
    },

    getExportsWithFilterQuery() {
      // console.log('send req');
      // radarService.getExportsFiltered(this);
      // radarService.getExportsFiltered(this, true)
      this.$nextTick(()=>{
        radarService.getExportsFiltered(this, true);
      })
      ;
    },
    setDateRangeFilterType(type) {
      this.filters.dataRangeFilterType = type;
      this.getExportsWithFilterQuery();
    },
    toggleFilterByDateRange() {
      this.getExportsWithFilterQuery();
    },
    toggleIsFiltersForCpeListBlockShowing() {
      if (this.filtersForCpeList.isFiltersForCpeListBlockShowing) {
        // this.clearFiltersForCpeList();
        this.filtersForCpeList.isFiltersForCpeListBlockShowing = false;
      } else {
        this.filtersForCpeList.isFiltersForCpeListBlockShowing = true;
        this.filtersForCpeList.isFiltersForCpeListEnabled = true;
        // this.filtersForCpeList.radarStatus = 'Enabled';
        this.selectLocationInCpesFilterForModal()
      }
    },
    clearFiltersForCpeList() {
      this.filtersForCpeList = {
        isFiltersForCpeListEnabled: false,
        isFiltersForCpeListBlockShowing: false,
        location: 'All locations',
        radarStatus: 'Any'
      };
    },
    changeContinuously() {
      if (this.isContinuously) {
        this.drPickerDateForExport.endDate = 0;
      } else {
        this.drPickerDateForExport.endDate = moment().valueOf();
      }
    },
    handleDateChangeForRating(newDate) {
      // this.drPickerDateForExport.startDate = newDate.start.valueOf();
      // this.drPickerDateForExport.endDate = newDate.end.valueOf();
    },
    changeType(val) {
      this.newShareBusinessIdSelectedObject = {};
      this.isShowingBisIdError = false;
      this.isShowingCTNError = false;
      // if (val !== 'email') {
      //   this.editExport.hash = true;
      // }

      if (val !== 'email' && val !== 'beepro') {
        this.editExport.hash = true;
      }

      if (this.isNmsTest) {
        this.editExport.creds.share = [];
      }

      if (val === 'beepro') {
        this.editExport.format = 'csv';
        this.editExport.creds.subject = this.editExport.name;
        this.editExport.hash = false;
      }

      // if (val === 'beepro' && this.isNewExport && this.beeProBusinessIDForCurrentUser !== undefined) {
      //   this.$nextTick(() => {
      //     this.fillNewShareBusinessIdSelectedObjectByBisId(this.beeProBusinessIDForCurrentUser.toString())
      //     this.editExport.creds.share.push(this.beeProBusinessIDForCurrentUser.toString());
      //   });
      //
      // }

      if (val === 'beepro' && this.isNewExport) {
        this.$nextTick(() => {
          if (this.usersWithBeeProCTNLength === 1) {
            console.log(this.usersWithBeeProBisId[0].bee_pro_ctn, this.usersWithBeeProCTN[0].bee_pro_bis_id);
            this.editExport.ctn = this.usersWithBeeProCTN[0].bee_pro_ctn;
            this.fillNewShareBusinessIdSelectedObjectByCTN(this.editExport.ctn);
            this.newShareBusinessId = this.usersWithBeeProCTN[0].bee_pro_bis_id;
            this.addBusinessId();
          }
        });
      }
    },
    changeForBeeline(val) {
      // this.editExport.ctn = '';
      this.newShareBusinessIdSelectedObject = {};
      this.isShowingBisIdError = false;
      this.isShowingCTNError = false;
      this.editExport.creds.share = [];

      if (val === 'beepro') {
        this.editExport.format = 'csv';
        this.editExport.creds.subject = this.editExport.name;
        this.editExport.hash = false;
      }

      // if (val ==='beepro' && this.isNewExport && this.beeProBusinessIDForCurrentUser !== undefined){
      //   this.fillNewShareBusinessIdSelectedObjectByBisId(this.beeProBusinessIDForCurrentUser.toString())
      //   this.editExport.creds.share.push(this.beeProBusinessIDForCurrentUser.toString())
      // }

      if (val === 'beepro' && this.isNewExport) {
        if (this.usersWithBeeProCTNLength === 1) {
          console.log(this.usersWithBeeProBisId[0].bee_pro_ctn, this.usersWithBeeProCTN[0].bee_pro_bis_id);
          this.editExport.ctn = this.usersWithBeeProCTN[0].bee_pro_ctn;
          this.fillNewShareBusinessIdSelectedObjectByCTN(this.editExport.ctn);
          this.newShareBusinessId = this.usersWithBeeProCTN[0].bee_pro_bis_id;
          this.addBusinessId();
        }
      }

      if (val === 'email') {
        this.editExport.format = 'txt';
      }
    },
    openExportModalForCreateNewExportWithSettingsFromVisitors(recivedSettingsForNewExport) {
      // применяется для особого открытия модалки создания выгрузки
      // происходит наполнение данными переданми из раздела Посетители.
      this.openExportModal(true, undefined, recivedSettingsForNewExport);
    },
    openExportModal(isNew, rExport = undefined, recivedSettingsForNewExport = undefined) {
      this.isShowingDateRangeLimitExceededError = false;
      this.dateRangeLimitExceededErrorRangeLimit = 0;
      this.errors.clear();
      this.hideWifiTargetDisabledOnAllSelectedCPESMsg();
      this.showDescriptionInCpesFilter = false;
      this.isShowingBisIdError = false;
      this.isShowingCTNError = false;
      this.isShowDatepickerInModal = false;
      this.$nextTick(() => {
        this.isShowDatepickerInModal = true;
      });
      // this.clearFiltersForCpeList();
      if (!this.isSelectedDefaultLocationForRequests) {
        this.clearFiltersForCpeList();
      }
      this.editExportCompany = 'All companys';
      this.isExportModalOpen = true;
      this.exportCpes = [];
      if (isNew) {
        const location = this.$store.state.userData.base_location;
        this.isNewExport = true;
        if (this.isBeelineBranding) {
          // console.log('beeline');
          this.editExport = {
            ctn: '',
            type: 'beepro',
            hash: false,
            filter: 'all',
            format: 'csv',
            base_location: location !== undefined ? location : '',
            creds: {
              share: []
            },
            period: {
              start: moment().subtract(24, 'hours').valueOf(),
              stop: moment().valueOf()
            },
            auto: false
          };
          this.$nextTick(() => {
            this.changeForBeeline('beepro');
          });
        } else {
          // console.log('not beeline');
          this.editExport = {
            ctn: '',
            type: 'email',
            filter: 'all',
            format: 'txt',
            base_location: location !== undefined ? location : '',
            creds: {
              share: []
            },
            period: {
              start: moment().subtract(24, 'hours').valueOf(),
              stop: moment().valueOf()
            }
          };
        }
        if (recivedSettingsForNewExport !== undefined) {
          // наполняем поля в модалке создания новой выгрузки значенями передаными из раздела
          // Посетители + перерисовываем модалку чтобы они корректно отобразались
          this.isExportModalOpen = false;
          this.drPickerDateForExport.startDate = recivedSettingsForNewExport.dateRange.startDate;
          this.drPickerDateForExport.endDate = recivedSettingsForNewExport.dateRange.endDate;
          this.exportCpes = JSON.parse(JSON.stringify(recivedSettingsForNewExport.cpes));
          this.editExport.base_location = recivedSettingsForNewExport.locationId;
          this.editExportCompany = JSON.parse(JSON.stringify(recivedSettingsForNewExport.company));
          this.filtersForCpeList.location = recivedSettingsForNewExport.locationId;
          this.filtersForCpeList.isFiltersForCpeListEnabled = true;
          this.filtersForCpeList.isFiltersForCpeListBlockShowing = true;
          this.selectLocationInCpesFilterForModal()
          this.$nextTick(() => {
            this.isExportModalOpen = true;
          });
        }
      } else {
        this.isNewExport = false;
        this.editExport = JSON.parse(JSON.stringify(rExport));

        this.drPickerDateForExport.startDate = this.editExport.period.start * 1000;
        this.drPickerDateForExport.endDate = this.editExport.period.stop * 1000;

        if (this.editExport.period.stop === 0) {
          this.isContinuously = true;
        }

        // if(this.isBeelineBranding || this.isNmsTest){
        //   if(this.editExport.type === 'beepro' && Object.prototype.hasOwnProperty.call(this.editExport, 'creds') && typeof this.editExport.creds === 'object' &&
        //   Object.prototype.hasOwnProperty.call(this.editExport.creds, 'share') && Array.isArray(this.editExport.creds.share) &&
        //   this.editExport.creds.share.length > 0){
        //     if (this.findUserObjectByBusinessId(this.editExport.creds.share) !== undefined){
        //       this.fillNewShareBusinessIdSelectedObjectByBisId(this.editExport.creds.share)
        //     }
        //   }
        // }
        this.newShareBusinessIdSelectedObject = {};
        if (this.isBeelineBranding || this.isNmsTest) {
          if (this.editExport.type === 'beepro' && this.editExport.ctn !== '') {
            const user_obj = this.findUserObjectByCTN(this.editExport.ctn);
            if (user_obj !== undefined) {
              this.fillNewShareBusinessIdSelectedObjectByCTN(this.editExport.ctn);
              this.newShareBusinessId = user_obj.bee_pro_bis_id;
              this.addBusinessId();
            }
          }
        }

        const { cpesList } = this.$store.state;
        if (cpesList && this.editExport && this.editExport.cpes) {
          const cpesItems = helpers.transformArrayToObject(cpesList);
          this.editExport.cpes.map((cpe) => {
            const fullCpe = cpesItems[cpe];
            fullCpe.id = cpe;
            this.exportCpes.push(fullCpe);
          });
        }
      }
    },
    openExportModalAndCreateCopy(rExport) {
      this.isShowDatepickerInModal = false;
      this.$nextTick(() => {
        this.isShowDatepickerInModal = true;
      });
      this.clearFiltersForCpeList();
      this.isExportModalOpen = true;
      this.isNewExport = true;
      this.exportCpes = [];
      // if (isNew) {
      //   const location = this.$store.state.userData.base_location;
      //   this.isNewExport = true;
      //   if (this.isBeelineBranding) {
      //     // console.log('beeline');
      //     this.editExport = {
      //       type: 'email',
      //       hash: true,
      //       filter: 'all',
      //       format: 'txt',
      //       base_location: location !== undefined ? location : '',
      //       creds: {
      //         share: []
      //       },
      //       period: {
      //         start: moment().subtract(24, 'hours').valueOf(),
      //         stop: moment().valueOf()
      //       }
      //     };
      //   } else {
      //     // console.log('not beeline');
      //     this.editExport = {
      //       type: 'email',
      //       filter: 'all',
      //       format: 'txt',
      //       base_location: location !== undefined ? location : '',
      //       creds: {
      //         share: []
      //       },
      //       period: {
      //         start: moment().subtract(24, 'hours').valueOf(),
      //         stop: moment().valueOf()
      //       }
      //     };
      //   }
      // } else {

      this.editExport = JSON.parse(JSON.stringify(rExport));
      this.editExport.name = '';
      if (this.editExport.hasOwnProperty('last_at')) {
        delete this.editExport.last_at;
      }
      if (this.editExport.hasOwnProperty('create_at')) {
        delete this.editExport.create_at;
      }

      this.drPickerDateForExport.startDate = moment().subtract(24, 'hours').valueOf();
      this.drPickerDateForExport.endDate = moment().valueOf();

      if (this.editExport.period.stop === 0) {
        this.isContinuously = true;
      }

      const { cpesList } = this.$store.state;
      if (cpesList && this.editExport && this.editExport.cpes) {
        const cpesItems = helpers.transformArrayToObject(cpesList);
        this.editExport.cpes.map((cpe) => {
          const fullCpe = cpesItems[cpe];
          fullCpe.id = cpe;
          this.exportCpes.push(fullCpe);
        });
      }
    },
    cancelEditMode() {
      this.hideWifiTargetDisabledOnAllSelectedCPESMsg();
      this.errors.clear();
      this.clearFiltersForCpeList();
      this.isExportModalOpen = false;
      this.deletingExportModal = false;
      this.editExport = {};
      this.newShareEmail = '';
      this.newShareBusinessId = '';
      this.isContinuously = false;
      this.drPickerDateForExport = {
        startDate: moment().subtract(24, 'hours').valueOf(),
        endDate: moment().valueOf()
      };
    },
    createGroup() {},

    // updateExport(rExport) {
    //   if (rExport.type === 'beepro' && Object.prototype.hasOwnProperty.call(rExport, 'creds') && typeof rExport.creds === 'object' &&
    //     Object.prototype.hasOwnProperty.call(rExport.creds, 'share') && Array.isArray(rExport.creds.share) &&
    //     (rExport.creds.share.length === 0 || rExport.creds.share[0] == 0)) {
    //     this.isShowingBisIdError = true;
    //     return;
    //   }else{
    //     radarService.updateExport(this, rExport);
    //   }
    //
    // },

    updateExport(rExport) {
      if (rExport.type === 'beepro' && rExport.ctn === '') {
        this.isShowingCTNError = true;
      } else {
        radarService.updateExport(this, rExport);
      }
    },
    // crateExport(rExport) {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       if (rExport.type === 'beepro' && Object.prototype.hasOwnProperty.call(rExport, 'creds') && typeof rExport.creds === 'object' &&
    //         Object.prototype.hasOwnProperty.call(rExport.creds, 'share') && Array.isArray(rExport.creds.share) &&
    //         (rExport.creds.share.length === 0 || rExport.creds.share[0] == 0)) {
    //         this.isShowingBisIdError = true;
    //         return;
    //       }else{
    //         radarService.crateExport(this, rExport);
    //       }
    //
    //     }
    //   });
    // },

    crateExport(rExport) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (rExport.type === 'beepro' && rExport.ctn === '') {
            this.isShowingCTNError = true;
          } else {
            radarService.crateExport(this, rExport);
          }
        }
      });
    },

    deleteExport(rExport) {
      radarService.deleteExport(this, rExport);
    },
    refreshExportList() {
      this.resetFilters();
      // radarService.getExports(this);
      radarService.getExports(this, true);
    },
    addEmail() {
      this.$validator.validateAll({ email: this.newShareEmail }).then((result) => {
        if (result && this.editExport.creds.share) {
          if (this.editExport.creds.share.indexOf(this.newShareEmail) == -1 && this.newShareEmail) {
            this.editExport.creds.share.push(this.newShareEmail);
          } else {
            return;
          }
          this.newShareEmail = '';
          this.$validator.reset();
        }
      });
    },
    addBusinessId() {
      if (this.editExport.creds.share && this.newShareBusinessId != '') {
        if (this.editExport.creds.share.indexOf(this.newShareBusinessId.toString()) == -1 && this.newShareBusinessId) {
          this.editExport.creds.share = [];
          this.editExport.creds.share.push(this.newShareBusinessId.toString());
        } else {
          return;
        }
        this.newShareBusinessId = '';
      }
    },
    removeEmail(e) {
      if (this.isDisable || !this.editExport.creds.share) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.editExport.creds.share.splice(index, 1);
    },
    removeBusinessId(e) {
      if (this.isDisable || !this.editExport.creds.share) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.editExport.creds.share.splice(index, 1);
      // this.newShareBusinessIdSelectedObject = {}
    },
    // removeCTN() {
    //   // console.log('remove ctn')
    //   removeBusinessId(this.newShareBusinessIdSelectedObject.bee_pro_bis_id)
    //   this.newShareBusinessIdSelectedObject = {}
    //   this.editExport.ctn = '';
    // },

    removeCTN() {
      if (this.isDisable || !this.editExport.creds.share) {
        return;
      }
      // const selectedNode = e.target.closest('[data-index]');
      // const { index } = selectedNode.dataset;
      this.editExport.creds.share = [];
      // // console.log('remove ctn')
      // removeBusinessId(this.newShareBusinessIdSelectedObject.bee_pro_bis_id)
      this.newShareBusinessIdSelectedObject = {};
      this.editExport.ctn = '';
    },
    setFilter(filterName, value) {
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
        if (filterName === 'typeFilter') {
          this.getExportsWithFilterQuery();
        }
        return;
      }
      this.filters[filterName] = '';
      if (filterName === 'typeFilter') {
        this.getExportsWithFilterQuery();
      }
    },
    getPercentage(rExport) {
      const matched = rExport.state.matched_quantity;
      const unique = rExport.state.valid_unique_quantity;
      if (matched === undefined || unique === undefined || matched === 0 || unique === 0) {
        return '0%';
      }
      return `${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format((matched / unique) * 100)}%`;
    },
    locationName(id) {
      return commonService.showLocationFromId(id);
    },
    clearSelectedCpesList() {
      // console.log(this.exportCpes);
      // this.exportCpes = []
      while (this.exportCpes.length) {
        this.exportCpes.pop();
      }
    },
    selectAllFilteredCpes() {
      this.exportCpes = [];
      for (const cpe of this.filterdCpesList) {
        this.exportCpes.push(cpe);
      }
    },
    // addAllFilteredCpesToSelected() {
    //   this.hideWifiTargetDisabledOnAllSelectedCPESMsg();
    //   const existingCpeIdsInExportCpes = [];
    //   for (const existingCpe of this.exportCpes) {
    //     existingCpeIdsInExportCpes.push(existingCpe.id);
    //   }
    //   for (const cpe of this.filterdCpesList) {
    //     if (!existingCpeIdsInExportCpes.includes(cpe.id)) {
    //       this.exportCpes.push(cpe);
    //     }
    //   }
    //   let areAllSelectedCPESWithDisabledRadar = true;
    //   for (const cpe of this.exportCpes) {
    //     if (cpe.config.lbs_config.enabled === true) {
    //       areAllSelectedCPESWithDisabledRadar = false;
    //       break;
    //     }
    //   }
    //   if (areAllSelectedCPESWithDisabledRadar && this.exportCpes.length > 0) {
    //     // console.log('все CPE с выключеным радаром');
    //     this.showWifiTargetDisabledOnAllSelectedCPESMsg();
    //   }
    // }

   async addAllFilteredCpesToSelected() {
      this.hideWifiTargetDisabledOnAllSelectedCPESMsg();
      const existingCpeIdsInExportCpes = [];
      for (const existingCpe of this.exportCpes) {
        existingCpeIdsInExportCpes.push(existingCpe.id);
      }


      let allCpesForLocation = []
      if (this.locationIdForCpeSearchRequests) {
        allCpesForLocation = await this.getAllCpesForLocation(this.locationIdForCpeSearchRequests, false)
      } else {
        allCpesForLocation = await this.getAllCpesForLocation(undefined, true)
      }

            for (const cpe of allCpesForLocation) {
        if (!existingCpeIdsInExportCpes.includes(cpe.id)) {
          this.exportCpes.push(cpe);
        }
      }


      let areAllSelectedCPESWithDisabledRadar = true;
      for (const cpe of this.exportCpes) {
        if (cpe.config.lbs_config.enabled === true) {
          areAllSelectedCPESWithDisabledRadar = false;
          break;
        }
      }
      if (areAllSelectedCPESWithDisabledRadar && this.exportCpes.length > 0) {
        // console.log('все CPE с выключеным радаром');
        this.showWifiTargetDisabledOnAllSelectedCPESMsg();
      }
    }

  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm:ss ');
      }
    }
  },
  created() {
    let inHeaderLocationSelectedApplying = true;
    if (
      Object.prototype.hasOwnProperty.call(this.$route.params, 'settingsForNewExport') &&
      // проверяем не попалил ли в этот раздел в результатте нажатия кнопки Создать выгрузку
      // из раздела Посетители. (в этом случае сюда передается объект settingsForNewExport)
      typeof this.$route.params.settingsForNewExport === 'object'
    ) {
      inHeaderLocationSelectedApplying = false;
      if (this.isSelectedDefaultLocationForRequests) {
        inHeaderLocationSelectedApplying = true;
      }
      const recivedSettingsForNewExport = JSON.parse(JSON.stringify(this.$route.params.settingsForNewExport));
      // console.log(recivedSettingsForNewExport);
      this.openExportModalForCreateNewExportWithSettingsFromVisitors(recivedSettingsForNewExport);
    }


    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
      if (this.isSelectedDefaultLocationForRequests) {
        // console.log('here', locationForFiltering)
        this.filtersForCpeList.location = locationForFiltering;
      }
    }

    // cpeService.getAllCompactCpes(this);
    cpeService.getAllCompactCpesWithDataRemaining(this);
    // radarService.getExports(this);
    // radarService.getExports(this, true);
    if (this.filters.location === 'All locations') {
      radarService.getExports(this, true);
    } else {
      radarService.getExportsFiltered(this, true);
    }
    locationService.getLocations(this);
    if (this.isBeelineBranding || this.isNmsTest) {
      usersService.getUsersWithBeeProBisId(this);
      usersService.getUsersWithBeeProCTN(this);
    }
  }
};
</script>

<style lang="scss">
  .datepicker-custom-height-for-AnalyticsRadar_27 {
  height: 27px !important;
}
  .input-group-for-location-select-in-AnalyticsRadar--filters {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;
  }

  .select-location-in-AnalyticsRadar--filters .select__list-container {
    width: 400px;
  }

.disabled-wifi-target-for-exports {
  background-color: rgba(65, 184, 131, 0.61);
}
.daterangepicker.dropdown-menu {
  z-index: 1000000 !important;
  right: auto;
}

.export-img {
  max-height: 18px;
}

.export-img-mytarget {
  width: 37px;
}

.items_list {
  overflow-y: auto;
}
.fadeforcpefiltersblockinradar-enter-active,
.fadeforcpefiltersblockinradar-leave-active {
  transition: opacity 0.7s;
}
.fadeforcpefiltersblockinradar-enter, .fadeforcpefiltersblockinradar-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
/*  .switcher-radar-cpe-filters-enable label {*/
/*  height: 10px !important;*/
/*  margin: 0px !important;*/
/*    width: 30px !important;*/
/*    top: 5px !important;*/
/*}*/

/*.switcher-radar-cpe-filters-enable span.switch-handle, .switcher-radar-cpe-filters-enable span.switch-label {*/
/*  height: 10px !important;*/
/*}*/
/*.switcher-radar-cpe-filters-enable span.switch-handle {*/
/*  width: 10px !important;*/
/*}*/
.items_list-for-table {
  overflow: hidden !important;
}

.multiselect-max-height-in-radar-export-modal .multiselect__tags {
  max-height: 90px !important;
  padding: 7px !important;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.multiselect-max-height-in-radar-export-modal .multiselect__tags::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f1f1f1;
}

.multiselect-max-height-in-radar-export-modal .multiselect__tags::-webkit-scrollbar-thumb {
  background: lightgray;
}
</style>

<style lang="scss" scoped>
.maximum-Cpes-With-Enabled-WiFi-Target-Selected-Error-more {
  text-decoration: underline;
}
.user-manual-link-for-beeline {
  font-size: 0.95em;
}
.user-manual-link-for-nmstest {
  font-size: 0.89em;
}
.cpes-show-description {
  font-size: 0.7em;
}
.search-or-sorting-item {
  max-width: 300px;
  margin-bottom: 0.5em;
}
.datepicker-block-in-filter-radar-export {
  width: 100%;
}
.datepicker-block-in-filter-radar-export-switcher {
  position: relative !important;
}
.datepicker-block-in-filter-radar-export-datepicker {
  position: relative;
  top: 3px !important;
}
.cpe-select-block {
  position: relative;
}
.showing-filters-for-cpe-list-block-button {
  margin-bottom: 10px;
}
.filter-in-row {
  width: auto !important;
  min-width: 200px;
  flex-grow: 1;
}

.radar-max-width {
  max-width: 768px;
}

.clear-input-fix {
  top: 7px;
}

.sub-table td {
  padding: 0;
  border: 0;
}

.sub-table td:last-of-type {
  padding-left: 10px;
}
.min-width-300 {
  min-width: 300px !important;
}
.selected-cpes-counter-in-radar-export-modal {
  margin-top: -1.5em;
}
</style>
